.c-body {
	min-height: 100vh;
	display: flex;
	flex-direction: column;

	.c-site-footer {
		margin-top: auto;
	}
}

.c-body--kiosk {
	.c-body__main {
		flex-grow: 1;

		@include media-breakpoint-up(md) {
			min-height: calc(100vh - #{$header-height});
		}
	}

	#sisenseApp {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		transform-origin: 0 0;
	}
}

.c-page {}

.c-page-title {
	position: relative;
	z-index: 15;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-end;

	.c-button,
	[class*="c-link-cta"] {
		margin-bottom: $spacer-base;
	}
}

.c-page-subtitle {
	position: relative;
	color: #00000059;
	font-size: font-size(h6);
	margin-bottom: $spacer-base-small;
	font-weight: 500;
}