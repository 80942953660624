.customize-support {
	margin-top: - rem(32px);
	padding-top: rem(32px);
	@media screen and (max-width: 782px) {
		margin-top: - rem(46px);
		padding-top: rem(46px);
	}
	@media screen and (max-width: 601px) {

	}

	.site-header {
		&.js-is-sticky {
			@media screen and (min-width: 600px) and (max-width: 768px) {
				top: rem(46px) !important;
			}
			@media screen and (min-width: 769px) {
				padding-top: rem(46px) !important;
			}
			@media screen and (min-width: 783px) {
				padding-top: rem(32px) !important;
			}
		}
	}
}
