
.c-actions {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-left: - $grid-gutter / 2;
	margin-right: - $grid-gutter / 2;

	form,
	.c-form-element,
	.c-form-element__field {
		margin: 0;
	}
	p {
		margin-top: $spacer;
		margin-bottom: $spacer;
		color: $color-base-light;
		font-size: $form-text-size;

		.c-color--invert & {
			color: $color-base-invert-light;
		}
		.c-color--default & {
			color: $color-base-light;
		}

		&.c-caption {
			padding-top: $form-text-size - font-size(03);
			font-size: font-size(03);
		}
	}
	form {
		padding-top: $form-border-width;
	}

	&--divider-first {
		position: relative;
		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 50%;
			height: rem(24px);
			width: rem(1px);
			background: $color-border-base;
			transform: translate(-50%, -50%);

			.c-theme--dark & {
				background: $color-border-invert;
			}
		}
	}
	&--divider-last {
		position: relative;
		&:after {
			content: '';
			position: absolute;
			right: 0;
			top: 50%;
			height: rem(24px);
			width: rem(1px);
			background: $color-border-base;
			transform: translate(-50%, -50%);

			.c-theme--dark & {
				background: $color-border-invert;
			}
		}
	}
}

.c-actions--double {
	width: 200%;
}

.c-actions__col {
	position: relative;
	display: flex;
	align-items: center;
	margin-top: $spacer;
	margin-bottom: $spacer;
	padding-left: $grid-gutter / 2;
	padding-right: $grid-gutter / 2;

	> * {
		margin-left: 0.5em;

		&:first-child {
			margin-left: 0;
		}
	}

	@supports (column-gap: 0) {
		column-gap: 0.5em;
		> * {
			margin-left: 0;
		}
	}

	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 50%;
		height: rem(24px);
		width: rem(1px);
		background: $color-border-base;
		transform: translate(-50%, -50%);

		.c-theme--dark & {
			background: $color-border-invert;
		}
	}

	&:first-child {
		&:before {
			display: none;
		}
	}

	&.c-actions__col--no-divider {
		&:before {
			display: none;
		}
	}
	+ .c-actions__col--no-divider {
		padding-left: 0;
	}
	&.c-actions__col--mobile-full {
		flex-grow: 1;
		@include media-breakpoint-up(sm) {
			flex-grow: 0;
		}

		form {
			flex-grow: 1;
			@include media-breakpoint-up(sm) {
				flex-grow: 0;
			}
		}
	}
}

.c-actions__icon {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;

	+ form,
	+ p,
	+ .c-dropdown-wrapper {
		margin-left: 0.5em;
	}

	&.c-actions__nav {
		@extend a;
		width: rem(24px);
		height: rem(24px);
		color: $color-base-lighter;
		cursor: pointer;

		&:hover {
			color: $color-links-darker;
		}
		&.is-selected {
			color: $color-accent;
		}

		.c-color--invert & {
			color: $color-base-invert-lighter;

			&:hover {
				color: $color-links-lighter;
			}
			&.is-selected {
				color: $color-accent-invert;
			}
		}
	}
}

.c-actions__item-search {
	@include media-breakpoint-up(lg) {
		width: rem(180px);
		display: flex;
		justify-content: flex-end;
	}

	.c-form-element__field {
		@include media-breakpoint-up(lg) {
			width: rem(75px);
			transition: all $transition-speed;
		}

		.c-form-element__icon--clear {
			display: none;
			left: auto;
			right: calc(#{$form-icon-size} + 0.5em);
			pointer-events: auto;
			cursor: pointer;

			&:hover {
				color: $color-accent;
			}
		}
	}

	&--active,
	&--focus {
		@include media-breakpoint-up(lg) {
			.c-form-element__field {
				width: rem(180px);
			}
		}
	}
	&--active {
		.c-form-element__field {
			.c-form-element__icon--clear {
				display: flex;
			}
			input {
				border-color: $color-border-base-dark;

				.c-theme--dark & {
					border-color: $color-border-invert;
				}
			}
		}
	}
}


/* Page Actions

===================================================================== */

.p-subheader {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.p-actions {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.p-action-link {
    position: relative;
	color: rgb(0, 0, 0);
    padding-left: 1.25rem;
	padding-right: 1.25rem;
    display: flex;
    align-items: center;
    gap: 10px;
    border-left: 1px solid black;
	cursor: pointer;
	transition-duration: 0.25s;

	&:hover {
		fill: blue;
		color: blue;
	}
}

.p-action-link__no-border {
	border: unset;
}

.p-action-name {
	font-weight: 500;
	margin-bottom: 0;
}

.p-action--padding-right {
	padding-right: 2.5rem;
}