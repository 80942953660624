.c-alert {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: $spacer-base;
  padding-top: $spacer-base - $spacer;
  padding-bottom: $spacer-base - $spacer;
  padding-left: ($grid-gutter / 2) - $spacer-base-small;
  padding-right: ($grid-gutter / 2) - $spacer-base-small;
  font-size: font-size(04);
  border-radius: $border-radius-large;
  background: $color-background-light2;
  .c-theme--dark & {
    background: $color-background-primary-dark;
  }

  &--dark {
    background: $color-background-dark;
    .c-theme--dark & {
      background: $color-background-invert;
    }
  }
  &--info {
    background: color(extra, info);
    .c-theme--dark & {
      background: color(extra, info);
    }
  }
  &--success {
    background: color(extra, success);
    .c-theme--dark & {
      background: color(extra, success);
    }
  }
  &--warning {
    background: color(extra, warning);
    .c-theme--dark & {
      background: color(extra, warning);
    }
  }
  &--error {
    background: color(extra, error);
    .c-theme--dark & {
      background: color(extra, error);
    }
  }
}

.c-alert__col {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-shrink: 0;
  padding-left: $spacer-base-small - $spacer;
  padding-right: $spacer-base-small;
  padding-top: $spacer;
  padding-bottom: $spacer;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol,
  .o-svg-icon,
  .c-button,
  [class*="c-link-cta"] {
    margin-left: $spacer;
    margin-bottom: 0;
  }
  .c-button,
  [class*="c-link-cta"] {
    margin-top: $spacer / 2;
    margin-bottom: $spacer / 2;
  }
  p {
    strong {
      margin-bottom: 0;
      margin-left: 0;
      font-size: 1em;
      font-weight: $font-weight-accent;
      color: $color-accent;

      .c-color--invert &,
      .c-color--white & {
        color: $color-accent-invert;
      }
      .c-color--default & {
        color: $color-accent;
      }
    }
  }
}
.c-alert__icon {
  .o-svg-icon {
    margin-bottom: 0;
    font-size: font-size(h4);
    color: $color-accent;
    line-height: $line-height-medium;

    .c-color--invert &,
    .c-color--white & {
      color: $color-accent-invert;
    }
    .c-color--default & {
      color: $color-accent;
    }
  }
}
.c-alert__body {
  flex-shrink: 1;
  flex-grow: 1;
  padding-top: $spacer * 3;
  padding-bottom: $spacer * 3;
}
.c-alert__footer {
  padding-top: 0;
  padding-bottom: 0;
}
.c-alert__close {
  @extend a;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $popup-header-height-small;
  height: $popup-header-height-small;
  font-size: font-size(02);
  color: $color-accent;
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    color: $color-accent;
    opacity: 1;
  }

  .c-color--invert & {
    color: $color-accent-invert;

    &:hover {
      color: $color-accent-invert;
    }
  }
}

@each $breakpoint-key, $breakpoint-value in $breakpoints {
  @include media-breakpoint-up(#{$breakpoint-key}) {
    @each $spacer-key, $spacer-value in $spacers {
      .c-alert--small\@#{$breakpoint-key} {
        font-size: font-size(03);
      }
      .c-alert--large\@#{$breakpoint-key} {
        font-size: font-size(05);
      }

      .c-alert--row\@#{$breakpoint-key} {
        flex-direction: row;
        align-items: center;
        padding-top: 0;
        padding-bottom: 0;
        padding-right: $popup-header-height-small;

        .c-alert__icon {
          &:first-child {
            padding-left: 0;
          }
        }
        .c-alert__footer {
          justify-content: flex-end;
          margin-left: auto;
        }
        .c-alert__close {
          top: 0;
          height: 100%;
          width: $popup-header-height;
        }

        &.c-alert--no-close {
          padding-right: ($grid-gutter / 2) - $spacer-base-small;

          .c-alert__close {
            display: none;
          }
        }
        &.c-alert--row-center {
          justify-content: center;

          .c-alert__body {
            flex-grow: 0;
          }
          .c-alert__footer {
            margin-left: 0;
          }
        }
      }

      .c-alert--float\@#{$breakpoint-key} {
        position: fixed;
        z-index: 2000;
        top: 0;
        bottom: auto;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - (#{$grid-gutter}));
        margin-top: ($header-height-mobile - $popup-header-height) / 2;
        margin-bottom: 0;
        box-shadow: $shadow-level-7;
        .c-theme--dark & {
          box-shadow: $shadow-level-7-invert;
        }

        .c-site-header__main & {
          &.c-alert {
            z-index: 2000;
            width: calc(100% - (#{$grid-gutter}));
            margin-top: ($header-height-mobile - $popup-header-height) / 2;
            margin-bottom: 0;
            @include media-breakpoint-up(lg) {
              min-width: 1px;
              width: calc(100% - (2 * #{$horizontal-spacer-large}));
            }
          }
        }
      }
      .c-alert--float-bottom\@#{$breakpoint-key} {
        position: fixed;
        z-index: 2000;
        top: auto;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - (#{$grid-gutter}));
        margin-top: 0;
        margin-bottom: $spacer-base-small;
        box-shadow: $shadow-level-7;
        .c-theme--dark & {
          box-shadow: $shadow-level-7-invert;
        }

        .c-site-header__main & {
          &.c-alert {
            z-index: 2000;
            width: calc(100% - (#{$grid-gutter}));
            margin-top: 0;
            margin-bottom: $spacer-base-small;
            @include media-breakpoint-up(lg) {
              min-width: 1px;
              width: calc(100% - (2 * #{$horizontal-spacer-large}));
            }
          }
        }
      }
    }
  }
}

.c-alert--anim {
  max-height: none;
  min-height: rem(40px);
  visibility: visible;
  opacity: 1;
  transition: all $transition-speed;
}
.c-alert--anim-hidden {
  max-height: 0;
  min-height: 0;
  visibility: hidden;
  opacity: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}
