
.c-site-logo {
	display: block;
	text-indent: -9000em;
	background: transparent url('../../assets/images/logo/logo-convertiv-basic--hor.svg') left center no-repeat;
	background-size: contain;

	&:before {
		content: '';
		display: block;
		padding-bottom: 100% / $logo-basic-hor-width-ratio; /* Logo width value when its height is 100 */
	}
}
.c-site-logo__name {
	font: 0/0 a;
	position: absolute;
	text-shadow: none;
	color: transparent;
	margin: 0;
}

.c-site-logo--basic {
	background-image: url('../../assets/images/logo/logo-basic--hor.svg');

	&:before {
		padding-bottom: 100% / $logo-basic-hor-width-ratio;
	}

	&.c-site-logo--primary {
		// background-image: url('../../assets/images/logo/logo-basic--hor--primary.svg');
	}
	&.c-site-logo--invert {
		background-image: url('../../assets/images/logo/logo-basic--hor--invert.svg');
	}

	&.c-site-logo {
		&--xs {
			width: $logo-basic-hor-width-ratio * rem(24px);
		}
		&--sm {
			width: $logo-basic-hor-width-ratio * rem(32px);
		}
	}
}

.c-site-logo--symbol {
	// background-image: url('../../assets/images/logo/logo-symbol.svg');

	&:before {
		padding-bottom: 100% / $logo-symbol-width-ratio;
	}

	&.c-site-logo--primary {
		// background-image: url('../../assets/images/logo/logo-symbol--primary.svg');
	}
	&.c-site-logo--invert {
		// background-image: url('../../assets/images/logo/logo-symbol--invert.svg');
	}

	&.c-site-logo {
		&--xs {
			width: $logo-symbol-width-ratio * rem(24px);
		}
		&--sm {
			width: $logo-symbol-width-ratio * rem(32px);
		}
	}
}
