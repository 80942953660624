
.c-modal__root {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 11111;
	overflow: auto;
	background-color: rgba($color-background-light2, .95);

	.c-theme--dark & {
		background-color: rgba($color-background-primary-dark, .95);
	}
}

.c-modal__wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	min-height: 100vh;
	width: 100%;

	&--sm {
		max-width: $site-width / 2;
	}
	&--md {
		max-width: $site-width * 3/4;
	}
	&--lg {
		max-width: $site-width;
	}
	&--xl {
		max-width: map-get($breakpoints, 'xl') + $grid-gutter;
	}
}

.c-modal {
	flex-grow: 1;
	position: relative;
	background: $color-background-base;
	border-radius: $border-radius-large;
	box-shadow: $shadow-blue-level-6;

	&--no-bg {
		background: none;
	}

	&--overflow {
		display: flex;
		flex-direction: column;
		max-height: calc(90vh);

		.c-modal__header {
			flex-shrink: 0;
			position: relative;
			margin-bottom: - $spacer-base;

			&:before {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				z-index: 10;
				height: $spacer-base;
				background: rgb(255,255,255);
				background: linear-gradient(180deg, rgba($color-background-base,1) 0%, rgba($color-background-base,.7) 50%, rgba($color-background-base,0) 100%);
			}
		}
		.c-modal__main {
			overflow: auto;
		}
		.c-modal__footer {
			flex-shrink: 0;
			position: relative;

			&:before {
				content: '';
				position: absolute;
				top: - $spacer-base;
				left: 0;
				right: 0;
				z-index: 10;
				height: $spacer-base;
				background: rgb(255,255,255);
				background: linear-gradient(0deg, rgba($color-background-base,1) 0%, rgba($color-background-base,.7) 50%, rgba($color-background-base,0) 100%);
			}
		}
	}

	.c-block {
		> [class*="o-container"] {
			@include media-breakpoint-up(lg) {
				padding-left: $grid-gutter;
				padding-right: $grid-gutter;
			}
		}
	}

	.c-theme--dark & {
		background: $color-background-dark;
		box-shadow: $shadow-level-7-invert;

		&--no-bg {
			background: none;
		}

		&--overflow {
			.c-modal__header {
				&:before {
					background: $color-background-dark;
					background: linear-gradient(180deg, rgba($color-background-dark,1) 0%, rgba($color-background-dark,.7) 50%, rgba($color-background-base,0) 100%);
				}
			}
			.c-modal__footer {
				&:before {
					background: $color-background-dark;
					background: linear-gradient(0deg, rgba($color-background-dark,1) 0%, rgba($color-background-dark,.7) 50%, rgba($color-background-base,0) 100%);
				}
			}
		}
	}
}

.c-modal__header {
	min-height: rem(40px);
}

.c-modal__footer {
}

.c-modal__headline {
	font-size: font-size(05);
	color: $color-base-light;

	a {
		@include link-basic;
		font-weight: $font-weight-accent;
	}

	.c-color--invert & {
		color: $color-base-invert-light;
	}
}

.c-modal-close {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 100;
	display: block;
	width: rem(40px);
	height: rem(40px);
	border-bottom-left-radius: $border-radius-large;
	transition: all $transition-speed;
	cursor: pointer;

	&:before,
	&:after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: rem(16px);
		height: rem(2px);
		border-radius: rem(1px);
		background: $color-base-lighter;
		transition: all $transition-speed;
		transform-origin: 0 0;
	}
	&:before {
		transform: rotate(45deg) translate(-50%, -50%);
	}
	&:after {
		transform: rotate(-45deg) translate(-50%, -50%);
	}

	&:hover {
		&:before,
		&:after {
			background: $color-links;
		}
	}

	&.mfp-close {
		text-indent: -9000em;
	}

	&--outside {
		position: fixed;
		width: rem(44px);
		height: rem(44px);

		&:before,
		&:after {
			width: rem(24px);
			background: color(neutral, 400);
		}

		&:hover {
			background: $color-links;

			&:before,
			&:after {
				background: $color-accent-invert;
			}
		}
	}

	.c-block & {
		position: static;
	}
}

.has-modal-open {
	overflow: hidden;
}
