

.c-hamburger-icon {
	position: relative;
	order: -1;
	width: $hamburger-icon-layer-width + (2 * $hamburger-icon-padding-x);
	height: $header-height-mobile;
	margin-right: - $hamburger-icon-padding-x;

	span {
		position: absolute;
		top: ($header-height-mobile / 2) - ($hamburger-icon-layer-height / 2);
		left: (($hamburger-icon-layer-width + (2 * $hamburger-icon-padding-x)) / 2) - ($hamburger-icon-layer-width / 2);
		width: $hamburger-icon-layer-width * 5 / 6;
		height: $hamburger-icon-layer-height;
		border-radius: $hamburger-icon-layer-border-radius;
		background: linear-gradient(to left, $hamburger-icon-layer-color 50%, $hamburger-icon-layer-color-hover 50%);
		background-size: 210% 100%;
		background-position: 99% 100%;
		transition: all $transition-speed;

		&:first-child {
			width: $hamburger-icon-layer-width * 2 / 3;
			margin-top: - ($hamburger-icon-layer-height + $hamburger-icon-layer-spacing);
		}
		&:last-child {
			width: $hamburger-icon-layer-width;
			margin-top: $hamburger-icon-layer-height + $hamburger-icon-layer-spacing;
		}
	}

	&:hover {
		span {
			background-position: 0% 100%;
		}
	}

	&.is-active {
		span {
			margin-top: 0;
			opacity: 0;
			width: $hamburger-icon-layer-width;
			background-position: 0% 100%;

			&:first-child {
				opacity: 1;
				transform: rotate(45deg);
			}
			&:last-child {
				opacity: 1;
				transform: rotate(-45deg);
			}
		}
	}
}
