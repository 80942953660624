/* =============================================================================
   GRID (settings/_grid.scss)
   ========================================================================== */

/**
 * 1. Set number of columns.
 * 2. Specify gutters width.
 */

$grid-columns: 12 !default; /* [1] */
$grid-gutter: rem(40px) !default; /* [2] */
$site-width: rem(1180px) + $grid-gutter;
