/* =============================================================================
   SPACING (utilities/_spacing.scss)
   ========================================================================== */

/* stylelint-disable declaration-no-important */

/**
 * Utilities for controlling an element's padding and margin.
 */

@each $breakpoint-key, $breakpoint-value in $breakpoints {
	@if $breakpoint-key == xs {
		@each $spacer-key, $spacer-value in $spacers {
			.u-mt-auto {
				margin-top: auto !important;
			}
			.u-mb-auto {
				margin-bottom: auto !important;
			}
			.u-my-auto {
				margin-top: auto !important;
				margin-bottom: auto !important;
			}
			.u-mt-#{$spacer-key}- {
				margin-top: - $spacer-value !important;
			}
		}
	} @else {
		@include media-breakpoint-up( #{$breakpoint-key} ) {
			@each $spacer-key, $spacer-value in $spacers {
				.u-mt-auto\@#{$breakpoint-key} {
					margin-top: auto !important;
				}
				.u-mb-auto\@#{$breakpoint-key} {
					margin-bottom: auto !important;
				}
				.u-my-auto\@#{$breakpoint-key} {
					margin-top: auto !important;
					margin-bottom: auto !important;
				}
				.u-mt-#{$spacer-key}-\@#{$breakpoint-key} {
					margin-top: - $spacer-value !important;
				}
			}
		}
	}
}
