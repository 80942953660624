
.c-popup {
	@extend .c-color--invert;
	border-radius: $border-radius-large;
	background: darken($color-background-dark, 4%);
	box-shadow: $shadow-level-6;
	visibility: hidden;
	opacity: 0;
	transform: translateY(1rem);
	transition: all $transition-speed;

	.has-popup-open & {
		visibility: visible;
		opacity: 1;
		transform: translateY(0);
	}
	.c-theme--dark & {
		background: $color-background-primary-dark;
		box-shadow: $shadow-level-6-invert;
	}
}

.c-popup__title {
	font-size: font-size(02);
	text-transform: uppercase;
	font-weight: $font-weight-accent;
	color: $color-base-lighter;
	line-height: $line-height-medium;

	.c-color--invert & {
		color: $color-base-invert-lighter;
	}
	.c-color--default & {
		color: $color-base-invert-lighter;
	}
}
.c-popup__username {
	flex-grow: 1;
	font-size: font-size(03);
	font-weight: $font-weight-accent;
	color: $color-accent;
	line-height: $line-height-medium;

	span {
		display: block;
		font-size: font-size(02);
		font-weight: $font-weight-base;
		color: $color-base-light;
	}

	.c-color--invert & {
		color: $color-accent-invert;

		span {
			color: $color-base-invert-light;
		}
	}
	.c-color--default & {
		color: $color-accent;

		span {
			color: $color-base-light;
		}
	}
}

.c-popup__divider {
	margin-bottom: $spacer-base-small;
	border: 1px dashed $color-border-base;
	border-top-width: 0;
	border-left-width: 0;
	border-right-width: 0;

	.c-color--invert & {
		border-color: $color-border-invert;
	}
	.c-color--default & {
		border-color: $color-border-base;
	}
}

.c-popup__info {
	text-align: center;
	font-size: font-size(03);
	color: $color-base-lighter;

	.c-color--invert & {
		color: $color-base-invert-lighter;
	}
	.c-color--default & {
		color: $color-base-lighter;
	}
}

.c-popup__list {
	margin-bottom: 0;

	li {
		margin-bottom: $spacer-base-small;
		padding-right: $spacer;

		p,
		a,
		.c-popup__link {
			margin: 0;
		}

		&.c-popup__divider {
			padding-bottom: $spacer-base-small;
		}
		&.c-popup__divider--top {
			padding-bottom: 0;
			padding-top: $spacer-base-small;
			border-bottom-width: 0;
			border-top-width: 1px;
		}
	}
	.c-popup__link {
		@include link-default;
	}
	a,
	.c-popup__link {
		display: flex;
		align-items: center;
		font-weight: $button-font-weight;
		font-size: $button-text-size;
		color: $color-base;

		span {
			span {
				display: block;
				color: $color-base-lighter;
				font-weight: $font-weight-base;
				font-size: font-size(01);
			}

			+ .o-svg-icon {
				margin-left: 0.75em;
				left: 0;
				transition: left $transition-speed, opacity $transition-speed;
			}
		}
		.o-svg-icon {
			flex-shrink: 0;
			align-self: flex-start;
			margin-top: ($line-height-base - 1)  / 2 * 1em;
			color: $color-accent;
			opacity: .35;
			transition: opacity $transition-speed;

			+ span {
				margin-left: 0.75em;
			}
		}

		&:hover {
			color: $color-links;

			.o-svg-icon {
				opacity: .85;
			}
		}

		.c-color--invert &,
		.c-color--white & {
			color: $color-base-invert;

			span {
				span {
					color: $color-base-invert-lighter;
				}
			}
			.o-svg-icon {
				color: $color-accent-invert;
			}
			&:hover {
				color: $color-links-lighter;
			}
		}
		.c-color--default & {
			color: $color-base;

			span {
				span {
					color: $color-base-lighter;
				}
			}
			.o-svg-icon {
				color: $color-accent;
			}
			&:hover {
				color: $color-links;
			}
		}
	}

	&.c-popup__notifications {
		a,
		.c-popup__link {
			font-weight: $font-weight-base;

			&:hover {
				color: $color-accent;
			}
			.c-color--invert &,
			.c-color--white & {
				color: $color-base-invert;
				&:hover {
					color: $color-accent-invert;
				}
			}
			.c-color--default & {
				color: $color-base;
				&:hover {
					color: $color-accent;
				}
			}
		}
		.c-popup__notifications-item--unread {
			a,
			.c-popup__link {
				font-weight: $font-weight-accent;
				color: $color-accent;

				.o-svg-icon {
					color: $color-links-lighter;
					opacity: 1;
				}

				&:hover {
					color: $color-links;
				}
				.c-color--invert &,
				.c-color--white & {
					color: $color-accent-invert;
					&:hover {
						color: $color-links-lighter;
					}
				}
				.c-color--default & {
					color: $color-accent;
					&:hover {
						color: $color-links;
					}
				}
			}
		}
	}

	&.c-popup__list--small {
		a,
		.c-popup__link {
			font-size: $button-text-size-small;

			span {
				span {
					line-height: $line-height-medium;
				}
			}
		}
	}
}

.c-popup__header {
	display: flex;
	align-items: center;
	min-height: $popup-header-height;
	padding-top: $spacer;
	padding-bottom: $spacer;
	padding-left: $grid-gutter / 2;
	padding-right: $popup-header-height + $spacer;
	background: $color-background-dark;
	border-top-left-radius: $border-radius-large;
	border-top-right-radius: $border-radius-large;

	p {
		margin-bottom: 0;
	}

	&--sm {
		min-height: $popup-header-height-small;
		padding-right: $popup-header-height-small + $spacer;
	}
	&--lg {
		min-height: $popup-header-height-large;
		padding-right: $popup-header-height-large + $spacer;
	}

	.c-theme--dark & {
		background: darken($color-background-dark, 50%);
	}
}
.c-popup__body {
	padding-top: $spacer-base - $spacer;
	padding-bottom: $spacer-base - $spacer-base-small;
	padding-left: $grid-gutter / 2;
	padding-right: $grid-gutter / 2;

	p {
		margin-bottom: $spacer-base-small;

		&.c-popup__info {
			position: relative;
			top: $spacer;
			margin-bottom: $spacer-base;
		}
	}
}

.c-popup__pointer {
	position: relative;

	&:before {
		content: '';
		position: absolute;
		top: 100%;
		left: 50%;
		width: 0;
		height: 0;
		margin-left: - rem(8px);
		border-style: solid;
		border-width: 0 rem(8px) rem(8px) rem(8px);
		border-color: transparent transparent $color-background-dark transparent;
		visibility: hidden;
		opacity: 0;
		transform: translateY(1rem);
		transition: all $transition-speed;
		pointer-events: none;
	}
}

.c-popup__toggle {
	@extend a;
	position: absolute;
	top: 0;
	right: 0;
	width: $popup-header-height;
	height: $popup-header-height;
	display: flex;
	align-items: center;
	justify-content: center;
	@include link-basic;
	font-size: font-size(01);
	opacity: .5;
	cursor: pointer;

	&:hover {
		opacity: 1;
	}

	.c-popup__header--sm & {
		width: $popup-header-height-small;
		height: $popup-header-height-small;
	}
	.c-popup__header--lg & {
		width: $popup-header-height-large;
		height: $popup-header-height-large;
	}
}
