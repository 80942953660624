/* =============================================================================
   SPACING (settings/_spacing.scss)
   ========================================================================== */

/**
 * Control the default spacing of most elements by modifying these variables.
 * You can add more entries to the $spacers map, should you need more variation.
 */

$spacer: rem(5px) !default;
$spacer-base-small: $spacer * 2 !default;
$spacer-base: $spacer * 4 !default;
$spacer-base-large: $spacer * 8 !default;

$spacer-section-small: rem(40px) !default;
$spacer-section: rem(60px) !default;
$spacer-section-large: rem(80px) !default;

$spacers: (
	'0': 0,
	'gutter': $grid-gutter,
	'gutter-half': $grid-gutter / 2,
	'spacer': $spacer,
	'spacer-base-small': $spacer-base-small,
	'spacer-base': $spacer-base,
	'spacer-base-large': $spacer-base-large,
	'spacer-section-small': $spacer-section-small,
	'spacer-section': $spacer-section,
	'spacer-section-large': $spacer-section-large,
) !default;
