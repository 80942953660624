
.c-social {
	&-list {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	    list-style: none;
	    margin: 0 0 $spacer-base 0;
	    padding: 0;

		a {
			@include link-basic;
			display: flex;
			align-items: center;
			justify-content: center;
			width: rem(40px);
			height: rem(40px);
			transition: all $transition-speed ease-in-out;

			.o-svg-icon {
				display: block;
				top: auto;
				font-size: font-size(03);
			}
		}

		&--push-left {
			margin-left: - rem(15px);
		}
	}

	&-sharing {
		.c-social-likes {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 0;

			li {
				display: block;
				vertical-align: middle;
				margin: $spacer-base-small $spacer-base-small 0 0;
				text-align: center;
				transition: all $transition-speed;

				a {
					display: flex;
					justify-content: center;
					align-items: center;
					text-align: center;
					width: rem(40px);
					height: rem(40px);
					color: $color-accent;
					border: 1px solid $color-border-base;
					border-radius: 50%;
					@include media-breakpoint-up(md) {
						width: rem(44px);
						height: rem(44px);
					}

					.o-svg-icon {
						font-size: font-size(03);
					}
				}

				&:hover {
					a {
						color: $color-accent-invert;
						background-color: $color-links;
						border-color: transparent;
					}
				}
			}

			.c-social-likes__item--facebook {
				&:hover {
					a {
						background-color: color(brand,facebook);
					}
				}
			}
			.c-social-likes__item--twitter {
				&:hover {
					a {
						background-color: color(brand,twitter);
					}
				}
			}
			.c-social-likes__item--hackernews {
				&:hover {
					a {
						background-color: color(brand,hackernews);
					}
				}
			}
			.c-social-likes__item--linkedin {
				&:hover {
					a {
						background-color: color(brand,linkedin);
					}
				}
			}
			.c-social-likes__item--reddit {
				&:hover {
					a {
						background-color: color(brand,reddit);
					}
				}
			}
			.c-social-likes__item--pocket {
				&:hover {
					a {
						background-color: color(brand,pocket);
					}
				}
			}
			.c-social-likes__item--kindle {
				&:hover {
					a {
						background-color: color(brand,kindle);
					}
				}
			}
			.c-social-likes__item--youtube {
				&:hover {
					a {
						background-color: color(brand,youtube);
					}
				}
			}
			.c-social-likes__item--pinterest {
				&:hover {
					a {
						background-color: color(brand,pinterest);
					}
				}
			}
			.c-social-likes__item--instagram {
				&:hover {
					a {
						background-color: color(brand,instagram);
					}
				}
			}
			.c-social-likes__item--whatsapp {
				&:hover {
					a {
						background-color: color(brand,whatsapp);
					}
				}
			}
			.c-social-likes__item--messenger {
				&:hover {
					a {
						background-color: color(brand,messenger);
					}
				}
			}
			.c-social-likes__item--tumblr {
				&:hover {
					a {
						background-color: color(brand,tumblr);
					}
				}
			}
			.c-social-likes__item--snapchat {
				&:hover {
					a {
						background-color: color(brand,snapchat);
					}
				}
			}
			.c-social-likes__item--flickr {
				&:hover {
					a {
						background-color: color(brand,flickr);
					}
				}
			}
			.c-social-likes__item--myspace {
				&:hover {
					a {
						background-color: color(brand,myspace);
					}
				}
			}
			.c-social-likes__item--soundcloud {
				&:hover {
					a {
						background-color: color(brand,soundcloud);
					}
				}
			}
			.c-social-likes__item--wikipedia {
				&:hover {
					a {
						background-color: color(brand,wikipedia);
					}
				}
			}
			.c-social-likes__item--rss {
				&:hover {
					a {
						background-color: color(brand,rss);
					}
				}
			}
			.c-social-likes__item--mailru {
				&:hover {
					a {
						background-color: color(brand,mailru);
					}
				}
			}
			.c-social-likes__item--odnoklassniki {
				&:hover {
					a {
						background-color: color(brand,odnoklassniki);
					}
				}
			}
			.c-social-likes__item--vkontakte {
				&:hover {
					a {
						background-color: color(brand,vkontakte);
					}
				}
			}
			.c-social-likes__item--embed {
				&:hover {
					a {
						background-color: color(brand,embed);
					}
				}
			}
		}


		.c-color--invert & {
			.social-likes__widget {
				color: $color-accent;
				border-color: $color-border-invert;

				&:hover {
					color: $color-accent;
					border-color: transparent;
				}
			}
		}
	}
}

.c-color--invert {
	.c-social {
		&-list {
			a {
				color: rgba(255,255,255,.7);
				&:hover {
					color: #fff;
				}
			}
		}
	}
}
