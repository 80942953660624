
table {
	border-collapse: separate;
	border-spacing: 0;

	th,
	td {
		padding: $spacer-base-small;
		text-align: left;
		border-bottom: 1px solid $color-border-base;
		vertical-align: middle;

		.c-theme--dark & {
			border-bottom-color: $color-border-invert;
		}
	}
	th {
		font-weight: $font-weight-accent;
	}

	thead {
		th,
		td {
			padding-top: $spacer;
			padding-bottom: $spacer;
			color: $color-base-lighter;
			font-weight: $font-weight-accent;
			border-top: 1px solid $color-border-base;
			background: rgba($color-background-light,.75);

			&:first-child {
				border-left: 1px solid $color-border-base;
				/*border-top-left-radius: $border-radius;
				border-bottom-left-radius: $border-radius;*/
			}
			&:last-child {
				border-right: 1px solid $color-border-base;
				/*border-top-right-radius: $border-radius;
				border-bottom-right-radius: $border-radius;*/
			}

			&.is-selected-column {
				color: $color-base;
			}

			.c-theme--dark & {
				background: rgba($color-background-light-invert,.75);
				border-top-color: $color-border-invert;

				&:first-child {
					border-left-color: $color-border-invert;
				}
				&:last-child {
					border-right-color: $color-border-invert;
				}
			}
			.c-color--invert & {
				color: $color-base-invert-lighter;

				&.is-selected-column {
					color: $color-base-invert;
				}
			}
		}
		span {
			position: relative;
			display: inline-block;
			padding-right: 0.5em;
			transition: all $transition-speed;

			.o-svg-icon {
				position: absolute;
				top: 50%;
				left: calc(100%);
				margin-top: -0.5em;
				opacity: .3;
				transition: transform $transition-speed, opacity $transition-speed;

				&.o-svg-small {
					margin-top: -0.25em;
				}
			}

			&.is-sortable {
				cursor: pointer;

				&:hover {
					color: $color-base;

					.o-svg-icon {
						opacity: 1;
					}
				}

				.c-color--invert & {
					&:hover {
						color: $color-base-invert;
					}
				}
			}
		}
	}

	tbody {
		th,
		td {
			&.is-selected-column {
				font-weight: $font-weight-accent;
				border-left: 2px solid $color-border-base;
				border-right: 2px solid $color-border-base;
				background: $color-background-base;
				box-shadow: $shadow-table;

				.c-theme--dark & {
					background: $color-background-primary-dark;
					border-left-color: $color-border-invert;
					border-right-color: $color-border-invert;
				}
			}
		}
		tr {
			&:first-child {
				th,
				td {
					&.is-selected-column {
						border-top: 1px solid $color-border-base;

						.c-theme--dark & {
							border-top-color: $color-border-invert;
						}
					}
				}
			}
			&:last-child {
				th,
				td {
					&.is-selected-column {
						border-bottom-width: 2px;
					}
				}
			}
		}
	}

	tfoot {
		th,
		td {
			font-weight: $font-weight-accent;
			border-top: 2px solid $color-border-base;

			&.is-selected-column {
				font-weight: $font-weight-accent;
			}
		}
	}
}

.c-table-wrapper {
	margin-left: - $grid-gutter / 2;
	margin-right: - $grid-gutter / 2;
	padding-left: $grid-gutter / 2;
	padding-right: $grid-gutter / 2;
	overflow-x: auto;
	@include media-breakpoint-up(md) {
		overflow-x: visible;
	}
}

.c-table__cell-span {
	display: block;
	font-size: 0.75em;
	color: $color-base-light;

	.c-color--invert & {
		color: $color-base-invert-light;
	}
}

.c-table__nowrap {
	white-space: nowrap;
}

@each $breakpoint-key, $breakpoint-value in $breakpoints {
	@if $breakpoint-key == xs {
		@each $spacer-key, $spacer-value in $spacers {
			.c-table--small {
				font-size: font-size(03);
			}
			.c-table--medium {
				font-size: font-size(04);
			}
			.c-table--large {
				font-size: font-size(05);
			}
		}
	} @else {
		@include media-breakpoint-up( #{$breakpoint-key} ) {
			@each $spacer-key, $spacer-value in $spacers {
				.c-table--small\@#{$breakpoint-key} {
					font-size: font-size(03);
				}
				.c-table--medium\@#{$breakpoint-key} {
					font-size: font-size(04);
				}
				.c-table--large\@#{$breakpoint-key} {
					font-size: font-size(05);
				}
			}
		}
	}
}

.c-table {
	position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
}

.c-table__header {
	position: relative;
    height: 2rem;
    width: 100%;
	padding-left: $spacer-section-small - $spacer-base;
	padding-right: $spacer-section-small - $spacer-base;
    display: flex;
    background-color: #f8fafa;
}

.c-table__header-title {
	font-weight: 700;
	color: #00000059;
	cursor: pointer;
	font-size: 0.9rem;

	&.c-table__header-ascending {
		color: #000000D9;
		background-image: url("../../assets/images/icon/sort-arrow-up.svg");
		background-repeat: no-repeat;
    	background-position-x: 0px;
    	background-position-y: center;
    	padding-left: 15px;
	}

	&.c-table__header-descending {
		color: #000000D9;
		background-image: url("../../assets/images/icon/sort-arrow-down.svg");
		background-repeat: no-repeat;
    	background-position-x: 0px;
    	background-position-y: center;
    	padding-left: 15px;
	}
}

.c-table-contents {
	position: relative;
	width: 100%;
	display: flex;
    flex-direction: column;
    align-items: center;
}

.c-table-item {
    height: fit-content;
    min-height: 1.5rem;
	width: 100%;
	border-bottom: 2px solid #f2f2f2;
}

.c-table-row {
    width: 100%;
	height: fit-content;
}

.c-table-row__content {
	width: 100%;
    display: flex;
    align-items: center;
	margin-bottom: unset;
	padding-left: $spacer-section-small - $spacer-base;
	padding-right: $spacer-section-small - $spacer-base;
}

.c-table-row__title {
	margin: 0.5rem 0;

	&.c-table-row__title-red-dot {
		color: #F2583E;
		background-image: url("../../assets/images/icon/red-dot.svg");
		background-repeat: no-repeat;
		background-position-x: 0px;
		background-position-y: center;
		padding-left: 15px;
	}

	&.c-table-row__title-red {
		color: #F2583E;
	}
}

.c-table-row__icon {
	position: absolute;
    height: 2rem;
    width: 2rem;
	right: 1rem;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
}

.c-table-row__additional-content {
	position: relative;
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    background-color: #f8fafa;
	border-top: 1px solid #DDE6E6;
	padding-left: $spacer-section-small - $spacer-base;
	padding-right: $spacer-section-small - $spacer-base;
}

.c-table__hidden {
	display: none !important;
}

.c-table__item--skeleton {
	width: 100%;

	.react-loading-skeleton {
	  display: block;
	  min-height: rem(50px);
	}
  
	.c-theme--dark & {
	  .react-loading-skeleton {
		background-color: rgba($color-background-invert, 0.2);
		background-image: linear-gradient(
		  90deg,
		  transparent,
		  rgba($color-background-dark, 0.6),
		  transparent
		);
	  }
	}
  }