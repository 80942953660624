
.c-video-wrapper {
	position: relative;
	height: 0;
	padding-bottom: 56.25%; /* 16:9 */

	iframe, embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
