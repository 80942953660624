
.c-accordion {
	transition: none;
	transition: box-shadow $transition-speed, border $transition-speed, background $transition-speed;

	&:hover {
		.c-accordion__header {
			.c-actions {
				visibility: visible;
				opacity: 1;
				transform: translateX(0);
			}
		}
	}

	&.is-open {
		.c-accordion__container {
			display: block;
		}
	}

	&.is-selected {
		z-index: 100;
	}
}

.c-accordion__header {
	position: relative;
	padding-right: ($grid-gutter / 2) + rem(16px);

	.c-actions {
		position: absolute;
		top: - rem(4px);
		right: $grid-gutter + rem(16px);
		z-index: 8;
		background: $color-background-base;
		visibility: hidden;
		opacity: 0;
		transform: translateX($grid-gutter / 2);
		transition: all $transition-speed-slower;

		&:before {
			content: '';
			position: absolute;
			right: 100%;
			top: 0;
			bottom: 0;
			width: $grid-gutter / 2;
			background: rgb(255,255,255);
			background: linear-gradient(270deg, rgba($color-background-base,1) 0%, rgba($color-background-base,0) 100%);
		}

		.is-selected & {
			background: $color-background-light2;

			&:before {
				background: linear-gradient(270deg, rgba($color-background-light2,1) 0%, rgba($color-background-light2,0) 100%);
			}
		}

		.c-theme--dark & {
			background: $color-background-dark;

			&:before {
				background: $color-background-dark;
				background: linear-gradient(270deg, rgba($color-background-dark,1) 0%, rgba($color-background-dark,0) 100%);
			}
		}
		.c-theme--dark .is-selected & {
			background: $color-background-light-invert;

			&:before {
				background: $color-background-light-invert;
				background: linear-gradient(270deg, rgba($color-background-light-invert,1) 0%, rgba($color-background-light-invert,0) 100%);
			}
		}
	}
}

.c-accordion__toggle {
	content: '';
	position: absolute;
	top: 0;
	right: - $grid-gutter / 2;
	z-index: 9;
	width: $grid-gutter + rem(16px);
	height: 1em * $line-height-base;
	opacity: .3;
	transition: all $transition-speed;
	cursor: pointer;

	&:before,
	&:after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 5;
		width: rem(16px);
		height: 2px;
		margin-top: - 1px;
		margin-left: - rem(8px);
		background: $color-accent;
		border-radius: 1px;
		transition: all $transition-speed;
	}
	&:after {
		transform: rotate(90deg);
	}

	&:hover {
		opacity: 1;

		&:before,
		&:after {
			background: $color-links;
		}
	}

	.is-open & {
		opacity: 1;

		&:before,
		&:after {
			transform: rotate(180deg);
			background: color(primary);
		}
		&:hover {
			&:before,
			&:after {
				background: $color-links;
			}
		}
	}

	&--high {
		top: - $spacer-base-small;
		height: (1rem * $line-height-base) + (2 * $spacer-base-small);
	}

	.c-theme--dark & {
		&:before,
		&:after {
			background: $color-accent-invert;
		}

		&:hover {
			&:before,
			&:after {
				background: $color-accent-invert;
			}
		}
	}
	.c-theme--dark .is-open & {
		&:before,
		&:after {
			background: $color-accent-invert;
		}
		&:hover {
			&:before,
			&:after {
				background: color(secondary);
			}
		}
	}
}

.c-accordion__container {
	display: none;
}
