
.c-page-nav {
	position: relative;
	z-index: 10;
	width: 100%;
	margin-bottom: $spacer-base;
	border-bottom: 1px solid $color-border-base;
	@include media-breakpoint-up(lg) {
		border: none;
		position: absolute;
		top: 0;
		left: - ($horizontal-spacer-large - $hamburger-icon-padding-x);
		width: auto;
	}
}

.c-page-nav__popup {
	position: absolute;
	left: - $grid-gutter / 2;
	right: - $grid-gutter / 2;
	top: 0;
	border-radius: 0;
	@include media-breakpoint-up(sm) {
		width: rem(400px);
		border-top-right-radius: $border-radius-large;
		border-bottom-right-radius: $border-radius-large;
	}

	.c-popup__body {
		padding-top: $spacer-base;
		padding-bottom: $spacer-base - $spacer;
		@include media-breakpoint-up(lg) {
			padding-top: $spacer-base + $spacer;
			padding-bottom: $spacer-base;
			padding-left: $horizontal-spacer-large;
			padding-right: $grid-gutter;
		}
	}
}

.c-page-nav__menu {
	margin-bottom: $spacer;

	a {
		display: block;
		margin-left: - $grid-gutter / 4;
		margin-right: - $grid-gutter / 4;
		padding: $spacer ($grid-gutter / 4);
		color: $color-base-invert;
		border-radius: $border-radius;

		&:hover {
			color: color-lighten(secondary);
		}
	}
	.is-selected {
		a {
			color: $color-accent-invert;
			text-shadow: $shadow-level-1;
			background: color(secondary);
			box-shadow: $shadow-blue-level-3;
		}
	}
}

.c-page-nav-toggle {
	position: relative;
	display: flex;
	align-items: center;
	height: $popup-header-height-large;
	margin-left: auto;
	font-size: $font-size-base;
	font-weight: $font-weight-accent;
	color: $color-base-light;
	cursor: pointer;
	@include media-breakpoint-up(lg) {
		margin-left: $horizontal-spacer-large - ($hamburger-icon-layer-width + (2 * $hamburger-icon-padding-x));
	}

	.c-page-nav-toggle__text {
		.c-page-nav-toggle__text--active {
			display: none;
		}
		@include media-breakpoint-up(lg) {
			span {
				display: none;
			}
		}
	}
	.c-page-nav-toggle__icon {
		position: relative;
		order: -1;
		width: $hamburger-icon-layer-width + (2 * $hamburger-icon-padding-x);
		height: $popup-header-height-large;
		margin-left: - $hamburger-icon-padding-x;

		span {
			position: absolute;
			top: ($popup-header-height-large / 2) - ($hamburger-icon-layer-height / 2);
			left: (($hamburger-icon-layer-width + (2 * $hamburger-icon-padding-x)) / 2) - ($hamburger-icon-layer-width / 2);
			width: $hamburger-icon-layer-width * 5 / 6;
			height: $hamburger-icon-layer-height;
			border-radius: $hamburger-icon-layer-border-radius;
			background: linear-gradient(to left, $hamburger-icon-layer-color 50%, $hamburger-icon-layer-color-hover 50%);
			background-size: 210% 100%;
			background-position: 99% 100%;
			transition: all $transition-speed;

			&:first-child {
				width: $hamburger-icon-layer-width * 2 / 3;
				margin-top: - ($hamburger-icon-layer-height + $hamburger-icon-layer-spacing);
			}
			&:last-child {
				width: $hamburger-icon-layer-width;
				margin-top: $hamburger-icon-layer-height + $hamburger-icon-layer-spacing;
			}
		}
	}

	&:hover {
		.c-page-nav-toggle__icon {
			span {
				background-position: 0% 100%;
			}
		}
	}

	.has-popup-open & {
		z-index: 10;

		.c-page-nav-toggle__text {
			@extend .c-color--invert;
			span {
				display: none
			}
			.c-page-nav-toggle__text--active {
				display: inline;
			}
		}
		.c-page-nav-toggle__icon {
			span {
				margin-top: 0;
				opacity: 0;
				width: $hamburger-icon-layer-width;
				background: $color-background-base;

				&:first-child {
					opacity: 1;
					transform: rotate(45deg);
				}
				&:last-child {
					opacity: 1;
					transform: rotate(-45deg);
				}
			}
		}
	}

	.c-theme--dark & {
		.c-page-nav-toggle__icon {
			span {
				background-image: linear-gradient(to left, rgba($color-background-base, .6) 50%, color(secondary) 50%);
			}
		}
	}

	.c-theme--dark .has-popup-open & {
		.c-page-nav-toggle__icon {
			span {
				background: $color-background-base;
			}
		}
	}
}
