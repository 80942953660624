.c-site-nav {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1001;
  width: $hamburger-icon-layer-width + (2 * $hamburger-icon-padding-x);
  max-width: rem(300px);
  max-height: $header-height-mobile;
  border-bottom-right-radius: $border-radius-large;
  border-top-right-radius: $border-radius-large;
  box-shadow: none;
  transition: all $transition-speed;

  @include media-breakpoint-up(md) {
    max-height: $header-height;
  }

  .c-popup {
    position: absolute;
    width: rem(300px);
    max-width: rem(300px);
    top: 1.25rem;
    left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .c-popup__header {
    padding-left: $grid-gutter;
    padding-right: ($grid-gutter / 2);
  }

  .c-popup__toggle {
    left: $spacer;
    right: auto;
  }

  .c-popup__title {
    text-transform: none;
  }

  .c-popup__list {
    a {
      border-radius: $border-radius;
      padding: $spacer (
        $grid-gutter / 2
      );

    &:hover {
      color: color(extra, white);
      background: $color-links;
      box-shadow: $shadow-popup-item;
    }
  }
}

// .c-popup__body {
//   padding-bottom: 0.9375rem;
// }

// .c-site-logo {
//   margin-left: $grid-gutter / 2;
//   margin-right: $grid-gutter / 2;
//   visibility: hidden;
//   opacity: 0;
//   transform: translateX(
//     -$hamburger-icon-layer-width - (2 * $hamburger-icon-padding-x)
//   );
//   transition: all $transition-speed-slower;

//   &.c-site-logo--symbol {
//     width: $logo-symbol-width-ratio * rem(24px);
//     @include media-breakpoint-up(md) {
//       width: $logo-symbol-width-ratio * rem(32px);
//     }
//   }
//   &.c-site-logo--basic {
//     width: $logo-basic-hor-width-ratio * rem(30px);
//     @include media-breakpoint-up(md) {
//       width: $logo-basic-hor-width-ratio * rem(35px);
//     }
//   }
// }

&.has-popup-open {
  max-height: 100vh;
  width: calc(100% - 1.25rem);

  // .c-site-logo {
  //   visibility: visible;
  //   opacity: 1;
  //   transform: translateX(0);
  // }
  .c-site-nav__header {
    width: auto;
    background-position-x: 0%;
  }

  .c-site-nav__popup {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
}

.c-theme--dark & {
  &.has-popup-open {
    background: $color-background-primary-dark;
    box-shadow: $shadow-level-7-invert;
  }
}
}

.c-site-nav__header {
  align-items: center;
  width: $hamburger-icon-layer-width + (2 * $hamburger-icon-padding-x);

  a {
    flex-shrink: 0;
  }
}

.c-site-nav-toggle {
  @extend a;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $hamburger-icon-layer-width + (2 * $hamburger-icon-padding-x);
  height: $header-height-mobile;
  font-size: font-size(06);
  font-weight: $font-weight-accent;
  transition: all $transition-speed ease-in-out;
  cursor: pointer;

  @include media-breakpoint-up(md) {
    height: $header-height;
  }

  .c-site-nav-toggle__text {
    display: none;
    margin-left: $spacer;
    font-size: font-size(02);
    color: $color-base-invert-light;
    font-weight: $font-weight-accent;
  }

  .c-site-nav-toggle__icon {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    order: -1;
    width: $hamburger-icon-layer-width;
    height: $hamburger-icon-layer-width;
    margin-left: $hamburger-icon-padding-x;

    // span {
    //   height: $hamburger-icon-layer-height;
    //   background-color: $hamburger-icon-layer-color;
    //   border-radius: $hamburger-icon-layer-border-radius;
    //   transition: all $transition-speed;
    //   transform: rotate(0deg);
    //   transform-origin: left center;
    //   &:nth-child(3) {
    //     width: $hamburger-icon-layer-width * 0.6;
    //   }
    // }
  }

  &.is-active {
    // .c-site-nav-toggle__icon {
    //   span {
    //     background: $color-base-invert-light;

    //     &:nth-child(1) {
    //       transform: scale(1, 1.25) rotate(45deg);
    //     }
    //     &:nth-child(2) {
    //       width: 0%;
    //       opacity: 0;
    //     }
    //     &:nth-child(3) {
    //       transform: scale(1, 1.25) rotate(-45deg);
    //       width: $hamburger-icon-layer-width;
    //     }
    //   }
    // }
    // .c-site-nav-toggle__text {
    //   display: block;
    // }
  }

  .c-theme--dark & {
    background-image: linear-gradient(to left,
        color(primary) 50%,
        color(secondary) 50%);

    &.is-active {
      background-image: linear-gradient(to left,
          $color-background-base 50%,
          color(secondary) 50%);
    }
  }
}

.c-site-nav__popup {
  visibility: hidden;
  opacity: 0;
  transform: translateY(-1rem);
  transition: all $transition-speed;
  padding: $spacer-base;
  padding-right: $grid-gutter / 2;
  padding-left: $grid-gutter / 2;
  background: $color-background-dark;
}

// .c-site-nav__menu {
//   display: flex;
//   flex-wrap: wrap;
//   margin-bottom: 0;
//   width: 100%;

//   li {
//     margin-bottom: $spacer-base-small;
//     width: 100%;
//   }
//   a {
//     display: flex;
//     border-radius: $border-radius;

//     &:hover {
//       background: $color-links;
//       box-shadow: $shadow-popup-item;
//     }

//     .c-site-nav__menu-icon {
//       flex-shrink: 0;
//       margin-right: $grid-gutter / 2;

//       .o-svg-icon {
//         width: 2.25em;
//         height: 2.25em;
//       }
//     }
//     .c-site-nav__menu-text {
//       line-height: $line-height-base * 1.25;
//       font-weight: $font-weight-base;
//       color: $color-accent-invert;
//       padding: 0 $spacer-base-small;
//       font-size: font-size(03);
//     }

//     .c-color--invert &,
//     .c-color--white & {
//       color: $color-accent-invert;
//     }
//     .c-color--default & {
//       color: $color-accent-invert;
//     }
//   }
// }