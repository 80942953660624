
.c-dropdown-wrapper {
	position: relative;
}

.c-dropdown-value {
	position: relative;
	display: inline-block;
	padding-right: ($form-select-icon-size + rem(8px));
	padding-top: ($form-field-height-small - $form-text-line-height) / 2;
	padding-bottom: ($form-field-height-small - $form-text-line-height) / 2;
	font-size: $form-text-size;
	font-weight: $form-text-weight;
	line-height: $line-height-medium;
	cursor: pointer;

	&:before {
		content: '';
		position: absolute;
		right: 0;
		top: 50%;
		width: $form-select-icon-size;
		height: $form-field-height;
		background-image: $form-select-icon-path;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		transform: translateY(-50%);
		opacity: $form-select-icon-opacity;
		pointer-events: none;
	}
	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		right: ($form-select-icon-size + rem(8px));
		height: $form-border-width;
		border-radius: $form-border-width / 2;
		background: linear-gradient(to left, transparent 50%, $form-border-color-hover 50%);
		background-position: 99% 0;
		background-size: 210% 100%;
		background-repeat: no-repeat;
		transition: all $transition-speed;
		@include media-breakpoint-up(sm) {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	&:hover {
		&:after {
			background-position-x: 0%;
		}
	}

	.has-selected-items & {
		&:after {
			background-image: linear-gradient(to left, $color-border-base-dark 50%, rgba(color(secondary), 1) 50%);

			.c-color--invert &,
			.c-color--white & {
				background-image: linear-gradient(to left, $color-border-invert 50%, rgba(color(secondary), 1) 50%);
			}
			.c-color--default & {
				background-image: linear-gradient(to left, $color-border-base-dark 50%, rgba(color(secondary), 1) 50%);
			}
		}
	}
	.has-dropdown-open & {
		color: $color-links;

		&:before {
			transform: rotate(180deg) translateY(50%);
		}
		&:after {
			background: $color-links;
		}
	}

	.c-color--invert & {
		&:before {
			background-image: $form-select-icon-path-invert;
		}
	}
}

.c-dropdown {
	display: block;
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
	background: $color-background-base;
	border-top: 1px solid $form-border-color-hover;
	box-shadow: $shadow-level-6;
	visibility: hidden;
	opacity: 0;
	transform: translateY(1rem);
	transition: all $transition-speed-faster;
	@include media-breakpoint-up(sm) {
		position: absolute;
		bottom: auto;
		top: 100%;
		left: - $form-padding-horizontal;
		right: auto;
		min-width: rem(200px);
		max-width: rem(400px);
		border-radius: $border-radius;
		border: 1px solid $color-border-base;
	}

	.has-dropdown-open & {
		visibility: visible;
		opacity: 1;
		transform: translateY(0);
	}

	&.c-dropdown--right {
		@include media-breakpoint-up(sm) {
			left: auto;
			right: - $form-padding-horizontal;
		}
	}

	&--sm {
		@include media-breakpoint-up(sm) {
			min-width: rem(150px);
		}
	}
	&--lg {
		@include media-breakpoint-up(sm) {
			min-width: rem(250px);
		}
	}
	&--xl {
		@include media-breakpoint-up(sm) {
			min-width: rem(300px);
		}
	}

	.c-theme--dark & {
		background: $color-background-primary-dark;
		box-shadow: $shadow-level-6-invert;
	}
}

.c-dropdown__title {
	display: block;
	font-size: font-size(02);
	font-weight: $font-weight-accent;
	color: $color-base-lighter;
	line-height: $line-height-medium;

	.c-color--invert & {
		color: $color-base-invert-lighter;
	}
	.c-color--default & {
		color: $color-base-invert-lighter;
	}
}

.c-dropdown__header {
	display: flex;
	align-items: center;
	min-height: $popup-header-height;
	padding-top: $spacer;
	padding-bottom: $spacer;
	padding-left: $grid-gutter / 2;
	padding-right: $popup-header-height + $spacer;
	background: $color-background-light;
	@include media-breakpoint-up(sm) {
		padding-left: $form-padding-horizontal;
		padding-right: $form-padding-horizontal;
		border-top-left-radius: $border-radius;
		border-top-right-radius: $border-radius;
	}

	p {
		margin-bottom: 0;
	}

	&--sm {
		min-height: $popup-header-height-small;
		padding-right: $popup-header-height-small + $spacer;
	}
	&--lg {
		min-height: $popup-header-height-large;
		padding-right: $popup-header-height-large + $spacer;
	}

	.c-theme--dark & {
		background: darken($color-background-dark, 50%);
	}
}

.c-dropdown__toggle {
	@extend a;
	position: absolute;
	top: 0;
	right: 0;
	width: $popup-header-height;
	height: $popup-header-height;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $color-accent;
	font-size: font-size(01);
	opacity: .4;
	cursor: pointer;
	@include media-breakpoint-up(sm) {
		display: none;
	}

	&:hover {
		color: $color-accent;
		opacity: 1;
	}

	.c-dropdown__header--sm & {
		width: $popup-header-height-small;
		height: $popup-header-height-small;
	}
	.c-dropdown__header--lg & {
		width: $popup-header-height-large;
		height: $popup-header-height-large;
	}
}

.c-dropdown__list {
	margin: 0;
	@include media-breakpoint-up(sm) {
		padding-left: $spacer;
		padding-right: $spacer;
	}

	span {
		display: block;
		padding: $spacer ($grid-gutter / 2);
		font-size: $form-text-size;
		font-weight: $form-text-weight;
		color: $color-base-light;
		transition: all $transition-speed;
		cursor: pointer;
		@include media-breakpoint-up(sm) {
			font-size: $form-text-size-small;
			padding: $spacer ($form-padding-horizontal - $spacer);
			border-radius: $border-radius;
		}

		&:hover {
			color: $color-accent;
		}

		.c-color--invert & {
			color: $color-base-invert-light;
			&:hover {
				color: $color-accent-invert;
			}
		}
		.c-color--default & {
			color: $color-base-light;
			&:hover {
				color: $color-accent;
			}
		}
	}

	.is-selected {
		span {
			color: $color-accent-invert;
			background: color(secondary);
		}
	}

	&.c-dropdown__list--buttons {
		@include media-breakpoint-up(sm) {
			display: flex;
			flex-wrap: wrap;
		}
	}
}

.c-dropdown__body {
	display: block;
	max-height: 80vh;
	padding: $spacer 0 $spacer-base;
	overflow: auto;
	@include media-breakpoint-up(sm) {
		max-height: rem(300px);
		padding-bottom: $spacer;
	}

	form,
	.c-dropdown__form {
		display: block;
		padding: 0 ($grid-gutter / 2);
		@include media-breakpoint-up(sm) {
			padding: 0 $form-padding-horizontal;
		}

		.c-form-element {
			margin-bottom: $spacer;
		}
	}
}
