
:root {
	color-scheme: light dark;
}

.c-theme--dark {
	background-color: $color-background-dark;

	.u-block--dark {
		display: block !important;
	}
	.u-inline-block--dark {
		display: inline-block !important;
	}
	.u-inline--dark {
		display: inline !important;
	}
	.u-hidden--dark {
		display: none !important;
	}
	.u-flex--dark {
		display: flex !important;
	}
	.u-inline-flex--dark {
		display: inline-flex !important;
	}
}

.c-theme-switcher {
}
