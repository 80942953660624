
.c-multiselect-container {

	.c-multiselect__control {
		min-height: 0;
		color: $form-fill-text-color;
		background-color: $form-fill-background;
		font-weight: 600;
		border-radius: $form-fill-field-border-radius;
		border: $form-border-width solid $form-fill-border-color;
		box-shadow: $form-fill-shadow;
		transition: all $transition-speed;

		&:hover {
			background-color: $form-fill-background-hover;
			border-color: $form-fill-border-color-hover;
			box-shadow: $form-fill-shadow-hover;
		}
		&:focus,
		&:active,
		&.c-multiselect__control--is-focused,
		&.c-multiselect__control--menu-is-open {
			background-color: $form-fill-background-selected;
			border-color: $form-fill-border-color-selected;
			box-shadow: $form-fill-shadow-active;
		}

		.c-theme--dark & {
			background-color: $form-fill-background-invert;
			border-color: $form-fill-border-color-invert;
			box-shadow: $form-fill-shadow-invert;

			&:hover {
				background-color: $form-fill-background-invert-hover;
				border-color: $form-fill-border-color-invert-hover;
				box-shadow: $form-fill-shadow-invert-hover;
			}
			&:focus,
			&:active,
			&.c-multiselect__control--is-focused,
			&.c-multiselect__control--menu-is-open {
				background-color: $form-fill-background-invert-selected;
				border-color: $form-fill-border-color-invert-selected;
				box-shadow: $form-fill-shadow-invert-active;
			}
		}
	}
	.c-multiselect__value-container {
		padding: rem(4px) 0 rem(4px) rem(4px);

		> div {
			padding: 0;
			margin: 0 ($form-padding-horizontal - rem(4px));
		}
		.c-multiselect__multi-value {
			margin: rem(2px);
			border-radius: $border-radius;

			.c-multiselect__multi-value__remove {
				border-radius: $border-radius;
				transition: all $transition-speed;
				cursor: pointer;
			}

			.c-theme--dark & {
				background: color(neutral, 300);
			}
		}
	}
	.c-multiselect__input {
		@include form-element--short;
		position: relative;
		top: rem(2px);
		margin-top: - rem(4px);
	}

	.c-multiselect__indicators {
		align-items: stretch;
	}
	.c-multiselect__clear-indicator {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 rem(8px);

		svg {
			transition: all $transition-speed;
		}
		&:before {
			content: '';
			position: absolute;
			right: 0;
			top: $spacer;
			bottom: $spacer;
			width: rem(1px);
			background: $color-border-base;
		}

		.c-theme--dark & {
			svg {
				color: $color-base-lighter;
				opacity: .6;
			}
			&:before {
				background: $color-border-base;
			}
			&:hover {
				svg {
					color: $color-base;
					opacity: 1;
				}
			}
		}
	}
	.c-multiselect__indicator-separator {
		display:none;
	}
	.c-multiselect__dropdown-indicator {
		position: relative;
		padding: 0;
		width: 2 * ($form-padding-horizontal - rem(1px)) + $form-select-icon-size;

		svg {
			display:none;
		}
		&:before {
			content: '';
			position: absolute;
			right: $form-padding-horizontal - rem(1px);
			top: 0;
			bottom: 0;
			width: $form-select-icon-size;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			opacity: $form-select-icon-opacity;
			pointer-events: none;
			background-image: $form-select-icon-path;
		}
	}


	.c-multiselect__menu {
		border-radius: 0;
		margin: 0;
		overflow: hidden;
	}
	.c-multiselect__menu-list {
		padding: $spacer 0;
	}
	.c-multiselect__option {
		padding: $spacer $form-padding-horizontal;
		color: $color-base;

		&.c-multiselect__option--is-focused {
			background: $form-fill-background;
		}
		&.c-multiselect__option--is-selected {
			color: color(primary);
			background: $color-background-base;
		}

		.c-color--invert & {
			color: $color-base;

			&.c-multiselect__option--is-focused {
				background: $form-fill-background;
			}
			&.c-multiselect__option--is-selected {
				color: color(primary);
				background: $color-background-base;
			}
		}
	}

}
