/* =============================================================================
   FLEXBOX (utilities/_flexbox.scss)
   ========================================================================== */


/* Justify Content
   ========================================================================== */

/**
 * Utilities for controlling flex items are positioned along a container's main
 * axis.
 */

@each $breakpoint-key, $breakpoint-value in $breakpoints {
	@if $breakpoint-key == xs {
		.u-justify-start {
			justify-content: flex-start !important;
		}

		.u-justify-center {
			justify-content: center !important;
		}

		.u-justify-end {
			justify-content: flex-end !important;
		}

		.u-justify-between {
			justify-content: space-between !important;
		}

		.u-justify-around {
			justify-content: space-around !important;
		}
	} @else {
		@include media-breakpoint-up(#{$breakpoint-key}) {
			.u-justify-start\@#{$breakpoint-key} {
				justify-content: flex-start !important;
			}

			.u-justify-center\@#{$breakpoint-key} {
				justify-content: center !important;
			}

			.u-justify-end\@#{$breakpoint-key} {
				justify-content: flex-end !important;
			}

			.u-justify-between\@#{$breakpoint-key} {
				justify-content: space-between !important;
			}

			.u-justify-around\@#{$breakpoint-key} {
				justify-content: space-around !important;
			}
		}
	}
}


/* Directions
   ========================================================================== */

@each $breakpoint-key, $breakpoint-value in $breakpoints {
	@if $breakpoint-key == xs {
		.u-flex-row {
			flex-direction: row !important;
		}
		.u-flex-column {
			flex-direction: column !important;
		}
		.u-flex-row-reverse {
			flex-direction: row-reverse !important;
		}
		.u-flex-column-reverse {
			flex-direction: column-reverse !important;
		}
	} @else {
		@include media-breakpoint-up(#{$breakpoint-key}) {
			.u-flex-row\@#{$breakpoint-key} {
				flex-direction: row !important;
			}
			.u-flex-column\@#{$breakpoint-key} {
				flex-direction: column !important;
			}
			.u-flex-row-reverse\@#{$breakpoint-key} {
				flex-direction: row-reverse !important;
			}
			.u-flex-column-reverse\@#{$breakpoint-key} {
				flex-direction: column-reverse !important;
			}
		}
	}
}


/* Align Self
   ========================================================================== */

@each $breakpoint-key, $breakpoint-value in $breakpoints {
	@if $breakpoint-key == xs {
		.u-self-start {
			align-self: flex-start !important;
		}
		.u-self-center {
			align-self: center !important;
		}
		.u-self-end {
			align-self: flex-end !important;
		}
		.u-self-stretch {
			align-self: stretch !important;
		}
	} @else {
		@include media-breakpoint-up(#{$breakpoint-key}) {
			.u-self-start\@#{$breakpoint-key} {
				align-self: flex-start !important;
			}
			.u-self-center\@#{$breakpoint-key} {
				align-self: center !important;
			}
			.u-self-end\@#{$breakpoint-key} {
				align-self: flex-end !important;
			}
			.u-self-stretch\@#{$breakpoint-key} {
				align-self: stretch !important;
			}
		}
	}
}


/* Wrapping
   ========================================================================== */

@each $breakpoint-key, $breakpoint-value in $breakpoints {
	@if $breakpoint-key == xs {
		.u-flex-wrap {
			flex-wrap: wrap !important;
		}
		.u-flex-wrap-reverse {
			flex-wrap: wrap-reverse !important;
		}
		.u-flex-nowrap {
			flex-wrap: nowrap !important;
		}
	} @else {
		@include media-breakpoint-up(#{$breakpoint-key}) {
			.u-flex-wrap\@#{$breakpoint-key} {
				flex-wrap: wrap !important;
			}
			.u-flex-wrap-reverse\@#{$breakpoint-key} {
				flex-wrap: wrap-reverse !important;
			}
			.u-flex-nowrap\@#{$breakpoint-key} {
				flex-wrap: nowrap !important;
			}
		}
	}
}


/* Other
   ========================================================================== */

@each $breakpoint-key, $breakpoint-value in $breakpoints {
	@if $breakpoint-key == xs {
		.u-flex-grow {
			flex-grow: 1 !important;
		}
		.u-flex-grow-0 {
			flex-grow: 0 !important;
		}
		.u-flex-shrink {
			flex-shrink: 1 !important;
		}
		.u-flex-shrink-0 {
			flex-shrink: 0 !important;
		}
	} @else {
		@include media-breakpoint-up(#{$breakpoint-key}) {
			.u-flex-grow\@#{$breakpoint-key} {
				flex-grow: 1 !important;
			}
			.u-flex-grow-0\@#{$breakpoint-key} {
				flex-grow: 0 !important;
			}
			.u-flex-shrink\@#{$breakpoint-key} {
				flex-shrink: 1 !important;
			}
			.u-flex-shrink-0\@#{$breakpoint-key} {
				flex-shrink: 0 !important;
			}
		}
	}
}
