
.c-block {
	position: relative;

	> [class*="o-container"] {
		position: relative;
		z-index: 5;
	}
}


/* Block spacings
   ========================================================================== */

.c-block--full-height {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	> [class*="o-container"] {
		min-height: 100vh;
		display: flex;
		flex-direction: column;
	}
}

[class*="c-block--bg"],
[class*="c-block--spacing-t"] {
	> [class*="o-container"] {
		padding-top: $spacer-base;
	}
}
.c-block {
	> [class*="o-container"] {
		padding-bottom: 0.001rem;
	}

	&[class*="c-block--columns"] {
		> [class*="o-container"] {
			padding-top: 0;
			padding-bottom: 0;
			> .o-row {
				> [class*="o-col"] {
					padding-top: $spacer-base;
					padding-bottom: 0.001rem;
				}
			}
		}
	}

	@each $breakpoint-key, $breakpoint-value in $breakpoints {
		@if $breakpoint-key == xs {
			&--spacing-t-extra-small {
				> [class*="o-container"] {
					padding-top: $spacer-section-small - $spacer-base;
				}
				&[class*="c-block--columns"] {
					> [class*="o-container"] {
						> .o-row {
							> [class*="o-col"] {
								padding-top: $spacer-section-small - $spacer-base;
							}
						}
					}
				}
			}
			&--spacing-t-small {
				> [class*="o-container"] {
					padding-top: $spacer-section-small;
				}
				&[class*="c-block--columns"] {
					> [class*="o-container"] {
						> .o-row {
							> [class*="o-col"] {
								padding-top: $spacer-section-small;
							}
						}
					}
				}
			}
			&--spacing-t {
				> [class*="o-container"] {
					padding-top: $spacer-section;
				}
				&[class*="c-block--columns"] {
					> [class*="o-container"] {
						> .o-row {
							> [class*="o-col"] {
								padding-top: $spacer-section;
							}
						}
					}
				}
			}
			&--spacing-t-large {
				> [class*="o-container"] {
					padding-top: $spacer-section-large;
				}
				&[class*="c-block--columns"] {
					> [class*="o-container"] {
						> .o-row {
							> [class*="o-col"] {
								padding-top: $spacer-section-large;
							}
						}
					}
				}
			}

			&--spacing-b-extra-small {
				> [class*="o-container"] {
					padding-bottom: $spacer-section-small - $spacer-base - $spacer-base;
				}
				&[class*="c-block--columns"] {
					> [class*="o-container"] {
						> .o-row {
							> [class*="o-col"] {
								padding-bottom: $spacer-section-small - $spacer-base - $spacer-base;
							}
						}
					}
				}
			}
			&--spacing-b-small {
				> [class*="o-container"] {
					padding-bottom: $spacer-section-small - $spacer-base;
				}
				&[class*="c-block--columns"] {
					> [class*="o-container"] {
						> .o-row {
							> [class*="o-col"] {
								padding-bottom: $spacer-section-small - $spacer-base;
							}
						}
					}
				}
			}
			&--spacing-b {
				> [class*="o-container"] {
					padding-bottom: $spacer-section - $spacer-base;
				}
				&[class*="c-block--columns"] {
					> [class*="o-container"] {
						> .o-row {
							> [class*="o-col"] {
								padding-bottom: $spacer-section - $spacer-base;
							}
						}
					}
				}
			}
			&--spacing-b-large {
				> [class*="o-container"] {
					padding-bottom: $spacer-section-large - $spacer-base;
				}
				&[class*="c-block--columns"] {
					> [class*="o-container"] {
						> .o-row {
							> [class*="o-col"] {
								padding-bottom: $spacer-section-large - $spacer-base;
							}
						}
					}
				}
			}
		} @else {
			@include media-breakpoint-up(#{$breakpoint-key}) {
				&--spacing-t-small\@#{$breakpoint-key} {
					> [class*="o-container"] {
						padding-top: $spacer-section-small;
					}
					&[class*="c-block--columns"] {
						> [class*="o-container"] {
							> .o-row {
								> [class*="o-col"] {
									padding-top: $spacer-section-small;
								}
							}
						}
					}
				}
				&--spacing-t\@#{$breakpoint-key} {
					> [class*="o-container"] {
						padding-top: $spacer-section;
					}
					&[class*="c-block--columns"] {
						> [class*="o-container"] {
							> .o-row {
								> [class*="o-col"] {
									padding-top: $spacer-section;
								}
							}
						}
					}
				}
				&--spacing-t-large\@#{$breakpoint-key} {
					> [class*="o-container"] {
						padding-top: $spacer-section-large;
					}
					&[class*="c-block--columns"] {
						> [class*="o-container"] {
							> .o-row {
								> [class*="o-col"] {
									padding-top: $spacer-section-large;
								}
							}
						}
					}
				}

				&--spacing-b-small\@#{$breakpoint-key} {
					> [class*="o-container"] {
						padding-bottom: $spacer-section-small - $spacer-base;
					}
					&[class*="c-block--columns"] {
						> [class*="o-container"] {
							> .o-row {
								> [class*="o-col"] {
									padding-bottom: $spacer-section-small - $spacer-base;
								}
							}
						}
					}
				}
				&--spacing-b\@#{$breakpoint-key} {
					> [class*="o-container"] {
						padding-bottom: $spacer-section - $spacer-base;
					}
					&[class*="c-block--columns"] {
						> [class*="o-container"] {
							> .o-row {
								> [class*="o-col"] {
									padding-bottom: $spacer-section - $spacer-base;
								}
							}
						}
					}
				}
				&--spacing-b-large\@#{$breakpoint-key} {
					> [class*="o-container"] {
						padding-bottom: $spacer-section-large - $spacer-base;
					}
					&[class*="c-block--columns"] {
						> [class*="o-container"] {
							> .o-row {
								> [class*="o-col"] {
									padding-bottom: $spacer-section-large - $spacer-base;
								}
							}
						}
					}
				}
			}
		}
	}

}


/* Block styles
   ========================================================================== */

.c-block--divider {
	border-bottom: 1px solid $color-border-base;
}
.c-block--divider-internal {
	> [class*="o-container"] {
		&:before {
			content: '';
			position: absolute;
			bottom: 0;
			left: $grid-gutter / 2;
			right: $grid-gutter / 2;
			height: 1px;
			background: $color-border-base;
		}
	}
}
.c-block--bg {
	background: $color-background-light;

	&-light {
		background: $color-background-light;
	}
	&-light2 {
		background: $color-background-light2;
	}
	&-dark {
		background: $color-background-dark;
	}
	&-primary {
		background: $color-background-primary;
	}
	&-secondary {
		background: $color-background-secondary;
	}
}

.c-block--bg-image {
	max-width: rem(1920px);
	margin-left: auto;
	margin-right: auto;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
}

.c-block__overlay {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2;
	opacity: .5;

	&--top {
		bottom: auto;
		height: rem(200px);
	}
	&--bottom {
		top: auto;
		height: rem(200px);
	}

	&--light {
		background: color(neutral, 100);
	}
	&--light-gradient {
		background: linear-gradient(to bottom, rgba(246,246,246,1) 0%,rgba(246,246,246,0) 100%);
	}
	&--dark {
		background: color(extra, black);
	}
	&--dark-gradient {
		background: linear-gradient(to bottom, rgba(51,51,51,1) 0%,rgba(51,51,51,0) 100%);
	}
	&--primary {
		background: $color-background-primary;
	}
	&--secondary {
		background: $color-background-secondary;
	}
	&--image {
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: cover;
		opacity: 1;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 2;
			opacity: .5;
			background: #000;
		}
	}

	&--opacity-0 {
		opacity: 0;
	}
	&--opacity-10 {
		opacity: .1;
	}
	&--opacity-20 {
		opacity: .2;
	}
	&--opacity-30 {
		opacity: .3;
	}
	&--opacity-40 {
		opacity: .4;
	}
	&--opacity-50 {
		opacity: .5;
	}
	&--opacity-60 {
		opacity: .6;
	}
	&--opacity-70 {
		opacity: .7;
	}
	&--opacity-80 {
		opacity: .8;
	}
	&--opacity-90 {
		opacity: .9;
	}
	&--opacity-100 {
		opacity: 1;
	}
}

.c-block--poster {
	&-200 {
		min-height: rem(200px);
	}
	&-300 {
		min-height: rem(300px);
	}
	&-400 {
		min-height: rem(400px);
	}
	&-500 {
		min-height: rem(500px);
	}
	&-600 {
		min-height: rem(600px);
	}
}


/* Block related
   ========================================================================== */

.c-block__header {
	margin-bottom: $spacer-base-large;
	width: 100%;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		margin-bottom: $spacer-base-small;
	}

	&--small {
		margin-bottom: $spacer-base;

		p {
			font-size: font-size(03);
		}
	}
	&--large {
		@include media-breakpoint-up(lg) {
			margin-bottom: $spacer-base-large + $spacer-base-small;

			h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			p {
				margin-bottom: $spacer-base;
			}
		}
	}

	&--xs {
		max-width: $site-width * 1/4;
		margin-left: auto;
		margin-right: auto;
	}
	&--sm {
		max-width: $site-width * 1/3;
		margin-left: auto;
		margin-right: auto;
	}
	&--md {
		max-width: $site-width / 2;
		margin-left: auto;
		margin-right: auto;
	}
	&--lg {
		max-width: $site-width * 2/3;
		margin-left: auto;
		margin-right: auto;
	}
	&--xl {
		max-width: $site-width * 3/4;
		margin-left: auto;
		margin-right: auto;
	}
}

.c-block__content {
	width: 100%;

	&--xs {
		max-width: $site-width * 1/4;
		margin-left: auto;
		margin-right: auto;
	}
	&--sm {
		max-width: $site-width * 1/3;
		margin-left: auto;
		margin-right: auto;
	}
	&--md {
		max-width: $site-width / 2;
		margin-left: auto;
		margin-right: auto;
	}
	&--lg {
		max-width: $site-width * 2/3;
		margin-left: auto;
		margin-right: auto;
	}
	&--xl {
		max-width: $site-width * 3/4;
		margin-left: auto;
		margin-right: auto;
	}
}

.c-block__icon {
	margin-bottom: $spacer-base;

	img {
		max-width: 4rem;
	}

	&--small {
		img {
			max-width: 2.75rem;
		}
	}
	&--large {
		img {
			max-width: 5rem;
		}
	}
}

.c-block__logo {
	max-width: rem(140px);
	margin-bottom: $spacer-base;
}

.c-block__footer {
	display: flex;
	margin-top: $spacer-base-large;
}

.c-block__row {
	margin-bottom: $spacer-base - $spacer;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	[class*="c-link-cta"] {
		margin-top: $spacer;
		margin-bottom: $spacer;
	}
	p {
		color: $color-base-light;

		.c-color--invert {
			color: $color-base-invert-light;
		}
		.c-color--default {
			color: $color-base-light;
		}
	}
}


[class*="c-block--columns"] > [class*="o-container"],
[class*="c-block--columns"] {
	> .o-row {
		> [class*="o-col"] {
			position: relative;

			&:before {
				content: '';
				position: absolute;
				top: -1px;
				left: 0;
				right: 0;
				height: 1px;
				background: $color-border-base;
			}
		}
	}

	&.c-color--invert,
	.c-color--invert & {
		> .o-row {
			> [class*="o-col"] {
				&:before {
					background: $color-border-invert;
				}
			}
		}
	}
}

@each $breakpoint-key, $breakpoint-value in $breakpoints {
	@if $breakpoint-key == xs {
		.c-block--columns > [class*="o-container"],
		.c-block--columns {
			> .o-row {
				> [class*="o-col"] {
					&:before {
						top: 0;
						left: 0;
						bottom: 0;
						width: 1px;
						height: auto;
					}

					&:first-child,
					&.c-block__column--no-divider {
						&:before {
							display: none;
						}
					}
				}
			}
		}
	} @else {
		@include media-breakpoint-up(#{$breakpoint-key}) {
			.c-block--columns\@#{$breakpoint-key} > [class*="o-container"],
			.c-block--columns\@#{$breakpoint-key} {
				> .o-row {
					> [class*="o-col"] {
						&:before {
							top: 0;
							left: 0;
							bottom: 0;
							width: 1px;
							height: auto;
						}

						&:first-child,
						&.c-block__column--no-divider {
							&:before {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}
