button,
input,
optgroup,
select,
textarea {
	font-family: $font-family-base;
	font-weight: $font-weight-base;
	color: $color-base;
}

@include input-text {
	appearance: none !important;
	box-shadow: none;
	outline: none;
	border: 0;
	border-radius: 0;
	transition-property: background-color, border-color;
	transition-duration: $transition-speed;

	&:focus {
		outline: none;
	}
}
