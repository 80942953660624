/* =============================================================================
   HAMBURGER-ICON (settings/_hamburger-icon.scss)
   ========================================================================== */

/**
 * 1. Regulate icon padding to increase / decrease click area.
 * 2. Regulate icon layers (lines) width and height values.
 * 3. Regulate spacing between icon layers (lines).
 * 4. Set icon layers (lines) background color.
 * 5. Set icon layers (lines) border radius size.
 */

$hamburger-icon-padding-x: $grid-gutter / 2 !default; /* [1] */
$hamburger-icon-padding-y: 1rem !default; /* [1] */
$hamburger-icon-layer-width: rem(16px) !default; /* [2] */
$hamburger-icon-layer-height: rem(2px) !default; /* [2] */
$hamburger-icon-layer-spacing: rem(9px) !default; /* [3] */
$hamburger-icon-layer-color: #000 !default; /* [4] */
$hamburger-icon-layer-color-invert: #fff !default;
$hamburger-icon-layer-color-hover: color-darken(primary) !default;
$hamburger-icon-layer-border-radius: rem(2px) !default; /* [5] */
