
.ie-10,
.ie-11 {
	body {
		overflow-y: auto;
	}
	.c-form-element--select {
		.c-form-element__field {
			&:before {
				display: none;
			}
			select {
				padding-right: 0 !important;
			}
		}
	}
	.c-card,
	.c-card__body,
	.c-card__figure,
	.c-card__footer {
		min-height: 1px;
	}
	.c-pagination {
		width: auto;
	}
}

.ie-11 {
}
