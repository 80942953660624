.c-site-select__wrapper {
	display: flex;
	flex-wrap: wrap;
	max-width: 100%;
}

.c-site-select {
	padding-right: 1.25rem;
	padding-left: 1.25rem;
	margin-top: $spacer;
	margin-bottom: $spacer;

	@include media-breakpoint-up(sm) {
		position: relative;
	}

	&:before {
		content: '';
		display: none;
		position: absolute;
		left: 0;
		top: 50%;
		height: rem(24px);
		width: rem(1px);
		background: $color-border-base;
		transform: translate(-50%, -50%);
		@include media-breakpoint-up(sm) {
			display: block;
		}

		.c-theme--dark & {
			background: $color-border-invert;
		}
	}

	.c-site-select__label {
		display: block;
		position: absolute;
		top: -0.5em;
		left: $grid-gutter / 4;
		color: $color-base-lighter;
		font-size: font-size(02);
		text-transform: uppercase;
		line-height: 1;

		.c-color--invert & {
			color: $color-base-invert-lighter;
		}
	}
	.c-site-select__name {
		@extend a;
		@include link-cta-light;
		max-width: 100%;
		color: $color-base;
		@include media-breakpoint-up(lg) {
			font-size: font-size(05);
		}

		span {
			position: relative;
			display: block;
			max-width: 100%;
		}

		&:hover {
			color: $color-accent;
		}

		.c-color--invert & {
			color: $color-base-invert;

			&:hover {
				color: $color-accent-invert;
			}
		}

		&:after {
			content: '';
			width: .375rem;
			height: .375rem;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			opacity: .5;
			pointer-events: none;
			background-image: $form-select-icon-path;
			margin-left: .375rem;

			.c-theme--dark & {
				background-image: $form-select-icon-path-invert;
			}
		}
	}

	.c-site-select__name--single {
		&::after {
			display: none;
		}
	}

	.c-site-select__toggle {
		position: relative;
		border-radius: $border-radius;
		transition: all $transition-speed;

		.c-site-select__name {
			&:hover {
				span {
					&:before {
						background-position-x: 0%;
					}
				}
			}
		}

		&.c-popup__pointer {
			&:before {
				top: auto;
				bottom: - ($header-height-mobile - $popup-header-height) / 2;

				.c-theme--dark & {
					border-color: transparent transparent darken($color-background-dark, 50%) transparent;
				}
			}
		}

		.c-color--invert & {
			color: $color-base-invert-lighter;
		}
		.c-theme--dark & {
			&:after {
				background-image: $form-select-icon-path-invert;
			}
			&:hover {
			}
		}
	}

	.c-popup {
		position: absolute;
		top: $header-height-mobile;
		left: 0;
		width: 100%;
		z-index: 99;
		@include media-breakpoint-up(sm) {
			top: 100%;
			top: calc(100% - #{$spacer} + #{rem(2px)});
			left: 0;
			width: rem(300px);
			max-width: none;
		}

		.c-popup__header {
			@include media-breakpoint-up(sm) {
				display: none;
			}
		}
		.c-popup__title {
			margin: 0;
			font-size: font-size(02);
			line-height: $line-height-medium;
		}
		.c-popup__toggle {
			@include media-breakpoint-up(sm) {
				display: none;
			}
		}
	}

	&.has-popup-open,
	.has-popup-open & {
		z-index: 100;

		.c-site-select__toggle {
			&:after {
				transform: rotate(180deg);
			}

			.c-site-select__label {
				.c-color--invert &,
				.c-color--white & {
					color: $color-base-invert-lighter;
				}
			}
			.c-site-select__name {
				color: $color-accent;

				span {
					&:before {
						background: color(secondary);
					}
				}

				.c-color--invert &,
				.c-color--white & {
					color: $color-accent-invert;
				}
			}

			&.c-popup__pointer {
				&:before {
					visibility: visible;
					opacity: 1;
					transform: translateY(0);

					@include media-breakpoint-up(sm) {
						display: none;
					}
				}
			}
		}
	}
}

.c-site-select-label {
	@include link-cta-light;
	display: block;
	color: $color-base-lighter;
	font-size: font-size(04);
	font-weight: $font-weight-base;
	line-height: 1;
	cursor: pointer;

	@include media-breakpoint-up(lg) {
		font-size: font-size(02);
		top: -.5em;
	}

	.c-color--invert & {
		color: $color-base-invert-lighter;
	}

	&::after {
		content: '';
		display: inline-block;
		width: .375rem;
		height: .375rem;
		background-image: $form-select-icon-path;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		opacity: .5;
		pointer-events: none;
		margin-left: .375rem;

		.c-theme--dark & {
			background-image: $form-select-icon-path-invert;
		}
	}
}

.c-site-select-label--single {
	&::after {
		display: none;
	}
}



/* TITLE SELECT
   ========================================================================== */

.c-site-title-select {
	position: relative;
	margin-bottom: $spacer-base;
	padding-top: $spacer-base-small;

	.c-site-title-select__label {
		position: absolute;
		bottom: 100%;
		left: 0;
		display: block;
		margin: 0;
		color: $color-base-lighter;
		font-size: font-size(02);
		font-weight: $font-weight-base;
		text-transform: uppercase;
		line-height: 1;

		.c-color--invert & {
			color: $color-base-invert-lighter;
		}
	}

	[class*="c-title"] {
		margin-bottom: 0;
		color: $color-accent;
		cursor: pointer;
		transition: all $transition-speed;

		span {
			position: relative;

			&:before {
				content: '';
				position: absolute;
				top: 100%;
				left: 0;
				right: 0;
				height: rem(2px);
				background: linear-gradient(to left, transparent 50%, rgba(color(primary), 1) 50%);
				background-position: 99% 0;
				background-size: 210% 100%;
				background-repeat: no-repeat;
				border-radius: rem(1px);
				transition: all $transition-speed;
			}
		}

		&:hover {
			color: $color-accent;

			span {
				&:before {
					background-position-x: 0%;
				}
			}
		}

		.c-color--invert & {
			color: $color-accent-invert;

			&:hover {
				color: $color-accent-invert;
			}
		}

		.c-theme--dark & {
			span {
				&:before {
					background-image: linear-gradient(to left, transparent 50%, rgba(color(secondary), 1) 50%);
				}
			}
		}
	}

	.c-site-title-select__toggle {
		position: relative;
		padding-right: $form-select-icon-size + 1rem;
		transition: all $transition-speed;
		cursor: pointer;

		&:after {
			content: '';
			position: absolute;
			right: 0.5rem;
			top: 0;
			bottom: 0;
			width: $form-select-icon-size;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			opacity: $form-select-icon-opacity;
			pointer-events: none;
			background-image: $form-select-icon-path;
		}

		.c-theme--dark & {
			&:after {
				background-image: $form-select-icon-path-invert;
			}
		}
	}

	.c-popup {
		position: absolute;
		top: 100%;
		top: calc(100% + #{rem(3px)});
		left: 0;
		width: rem(300px);
		max-width: none;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	&.has-popup-open,
	.has-popup-open & {
		z-index: 100;

		.c-site-title-select__toggle {
			&:after {
				transform: rotate(180deg);
			}

			[class*="c-title"] {
				color: $color-accent;

				span {
					&:before {
						background: color(secondary);
					}
				}

				.c-color--invert &,
				.c-color--white & {
					color: $color-accent-invert;
				}
			}

			&.c-popup__pointer {
				&:before {
					visibility: visible;
					opacity: 1;
					transform: translateY(0);

					@include media-breakpoint-up(sm) {
						display: none;
					}
				}
			}
		}
	}
}
