/* =============================================================================
   BACKGROUNDS (utilities/_backgrounds.scss)
   ========================================================================== */

/* stylelint-disable declaration-no-important */

/* Background Color
   ========================================================================== */

/**
 * Specific Dashboard colors.
 */

.u-bg-base {
	background-color: $color-background-base !important;
}
.u-bg-invert {
	background-color: $color-background-invert !important;
}

.u-bg-light {
	background-color: $color-background-light !important;
}
.u-bg-dark {
	background-color: $color-background-dark !important;
}

.u-bg-dash-light {
	background-color: $color-background-dash-light !important;
}
.u-bg-dash-dark {
	background-color: $color-background-dash-dark !important;
}
