
hr {
	border-color: $color-border-base;

	&.c-hr--dashed {
		border-style: dashed;
	}

	.c-theme--dark & {
		border-color: $color-border-invert;
	}
}
