
.c-login__flow {
	margin-top: auto;
	position: absolute;
	visibility: hidden;
	opacity: 0;
	transform: translateY(50%);
	transition: all $transition-speed;

	&.c-login__flow--visible {
		position: relative;
		visibility: visible;
		opacity: 1;
		transform: translateY(0);
	}
	&.c-login__flow--hidden {
		position: absolute;
		visibility: hidden;
		opacity: 0;
		transform: translateY(-50%);
	}

	&.c-login__flow--secondary {
		transition: all $transition-speed-slower;
	}
}
.c-login__flow-wrapper {
	flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
