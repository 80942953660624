/* =============================================================================
   TYPOGRAPHY (elements/_typography.scss)
   ========================================================================== */

body {
	font-family: $font-family-base;
	color: $color-base;
	font-weight: $font-weight-base;
}

em, i {
	font-style: italic;
}
strong {
	font-weight: $font-weight-accent;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: $font-weight-accent;
	color: $color-accent;
	line-height: $line-height-medium;

	strong {
		font-weight: $font-weight-accent;
	}
	a {
		font-weight: $font-weight-accent;
	}

	.c-color--invert &,
	.c-color--white & {
		color: $color-accent-invert;
	}
	.c-color--default & {
		color: $color-accent;
	}
}

h1 {
	font-size: font-size(h3);
	@include media-breakpoint-up(sm) {
		font-size: font-size(h2);
		line-height: $line-height-large;
	    letter-spacing: -0.025em;
	}
	@include media-breakpoint-up(lg) {
		font-size: font-size(h1);
	}
}
h2 {
	font-size: font-size(h4);
	@include media-breakpoint-up(sm) {
		font-size: font-size(h3);
	}
	@include media-breakpoint-up(lg) {
		font-size: font-size(h2);
		line-height: $line-height-large;
	    letter-spacing: -0.025em;
	}
}
h3 {
	font-size: font-size(h4);
	@include media-breakpoint-up(md) {
		font-size: font-size(h3);
	}
}
h4 {
	font-size: font-size(h5);
	@include media-breakpoint-up(md) {
		font-size: font-size(h4);
	}
}
h5 {
	font-size: font-size(h6);
	@include media-breakpoint-up(md) {
		font-size: font-size(h5);
	}
}
h6 {
	font-size: font-size(h6);
}

a {
	transition: all $transition-speed;
}

li {
	list-style: none;
}

cite {
	display: block;
	font-style: normal;
	quotes: none;
}

pre,
code,
xmp {
	color: $color-accent;
	font-weight: normal;
	background: rgba(10,10,10,.05);
	border-radius: rem(2px);
	border: 1px solid $color-border-base;

	p &,
	li &,
	td & {
		display: inline-block;
		margin: 0;
		padding-left: rem(2px);
		padding-right: rem(2px);
		line-height: $line-height-medium;
	}

	.c-color--invert & {
		color: $color-accent-invert;
		background: $color-background-primary-dark;
		border-color: $color-border-invert;
	}
}
