/* =============================================================================
   FORM (settings/_form.scss)
   ========================================================================== */


/* Element Sizes
   ========================================================================== */

/* Field border sizes */
$form-border-width: rem(2px) !default;
$form-border-radius: $border-radius !default;

/* Field height sizes */
$form-field-height-small: rem(36px) !default;
$form-field-height: rem(44px) !default;
$form-field-height-large: rem(54px) !default;
$form-field-height-extra-large: rem(64px) !default;

/* Text sizes */
$form-text-size-small: rem(14px) !default;
$form-text-size: rem(16px) !default;
$form-text-size-large: rem(18px) !default;
$form-text-size-extra-large: rem(32px) !default;
$form-text-line-height: (
	$form-text-size * 1.25) !default;
$form-text-line-height-extra-large: (
	$form-text-size-extra-large * 1.25) !default;

/* Textarea height sizes */
$form-textarea-height-small: rem(60px) !default;
$form-textarea-height: rem(100px) !default;
$form-textarea-height-large: rem(140px) !default;

/* Dropdown sizes */
$form-select-icon-size: rem(10px) !default;

/* Choice sizes */
$form-choice-width: rem(20px) !default;
$form-choice-border-width: $form-border-width !default;
$form-check-border-radius: $border-radius-small !default;
$form-check-icon-size: rem(12px) !default;
$form-radio-dot-size: rem(7px) !default;
$form-toggle-width: rem(40px) !default;
$form-toggle-dot-size: rem(14px) !default;

/* Icon sizes */
$form-icon-size-small: rem(12px) !default;
$form-icon-size: rem(14px) !default;
$form-icon-size-large: rem(18px) !default;
$form-icon-size-extra-large: rem(24px
);

/* Horizontal spacer sizes */
$form-padding-horizontal-small: (
	($form-field-height-small - $form-text-size-small) / 2) !default;
$form-padding-horizontal: (
	($form-field-height - $form-text-size) / 2) !default;
$form-padding-horizontal-large: (
	($form-field-height-large - $form-text-size-large) / 2) !default;

/* Other sizes */
$form-label-size: rem(12px) !default;
$form-help-text-size: rem(12px) !default;



/* Style - General
   ========================================================================== */

/* Text weights */
$form-text-weight: $font-weight-base !default;
$form-placeholder-weight: $font-weight-base !default;
$form-choice-weight: $font-weight-base !default;
$form-label-weight: $font-weight-base !default;
$form-help-weight: $font-weight-base !default;

/* Label styles */
$form-label-color: $color-base !default;
$form-label-color-focus: $color-accent !default;
$form-label-color-invert: $color-base-invert !default;
$form-label-color-invert-focus: $color-accent-invert !default;

/* Dropdown styles */
$form-select-icon-opacity: .7 !default;
$form-select-icon-path: url('../../assets/images/icon/form-dropdown.svg') !default;
$form-select-icon-path-invert: url('../../assets/images/icon/form-dropdown--white.svg') !default;

/* Choice styles */
$form-check-icon-path: url('../../assets/images/icon/form-check--white.svg') !default;
$form-check-icon-path-invert: url('../../assets/images/icon/form-check--white.svg') !default;
$form-check-indeterminate-icon-path: url('../../assets/images/icon/form-indeterminate--white.svg') !default;
$form-check-indeterminate-icon-path-invert: url('../../assets/images/icon/form-indeterminate--white.svg') !default;

$form-choice-color: $color-base-light !default;
$form-choice-color-hover: $color-base !default;
$form-choice-color-selected: $color-base !default;
$form-choice-color-invert: $color-base-invert-light !default;
$form-choice-color-invert-hover: $color-base-invert !default;
$form-choice-color-invert-selected: $color-base-invert !default;

$form-check-background: rgba(245, 245, 245, .9) !default;
$form-check-background-selected: color(secondary) !default;
$form-check-background-invert: transparent !default;
$form-check-background-invert-selected: color(secondary) !default;

$form-radio-background: transparent !default;
$form-radio-background-selected: color(secondary) !default;
$form-radio-background-invert: transparent !default;
$form-radio-background-invert-selected: color(secondary) !default;

$form-radio-dot-background: #fff !default;
$form-radio-dot-background-invert: #fff !default;

$form-toggle-background: $color-border-base-dark !default;
$form-toggle-background-hover: $color-border-base-darker !default;
$form-toggle-background-selected: color(secondary) !default;
$form-toggle-background-invert: $color-border-invert-light !default;
$form-toggle-background-invert-hover: $color-border-invert-lighter !default;
$form-toggle-background-invert-selected: $color-background-base !default;

$form-toggle-dot-background: $color-background-base !default;
$form-toggle-dot-background-selected: $color-background-base !default;
$form-toggle-dot-background-invert: $color-background-base !default;
$form-toggle-dot-background-invert-selected: color(secondary) !default;

$form-check-border-color: $color-border-base !default;
$form-check-border-color-hover: $color-border-base-darker !default;
$form-check-border-color-selected: $color-border-base !default;
$form-check-border-color-invert: $color-border-invert-lighter !default;
$form-check-border-color-invert-hover: $color-border-invert-lightest !default;
$form-check-border-color-invert-selected: $color-border-base !default;

$form-radio-border-color: $form-check-border-color !default;
$form-radio-border-color-hover: $form-check-border-color-hover !default;
$form-radio-border-color-selected: $form-check-border-color-selected !default;
$form-radio-border-color-invert: $form-check-border-color-invert !default;
$form-radio-border-color-invert-hover: $form-check-border-color-invert-hover !default;
$form-radio-border-color-invert-selected: $form-check-border-color-invert-selected !default;

$form-toggle-border-color: transparent !default;
$form-toggle-border-color-hover: transparent !default;
$form-toggle-border-color-selected: transparent !default;
$form-toggle-border-color-invert: transparent !default;
$form-toggle-border-color-invert-hover: transparent !default;
$form-toggle-border-color-invert-selected: transparent !default;



/* Style - Line
   ========================================================================== */

/* Field Styles */
$form-field-border-radius: $border-radius !default;

/* Field Border Colors */
$form-border-color: transparent !default;
$form-border-color-hover: color(secondary) !default;
$form-border-color-selected: color(primary) !default;
$form-border-color-error: color(extra, error) !default;
$form-border-color-invert: transparent !default;
$form-border-color-invert-hover: $form-check-border-color-invert-hover !default;
$form-border-color-invert-selected: rgba(255, 255, 255, 1) !default;
$form-border-color-invert-error: color(extra, error) !default;

/* Field Background Colors */
$form-background: transparent !default;
$form-background-hover: transparent !default;
$form-background-selected: transparent !default;
$form-background-error: transparent !default;
$form-background-invert: transparent !default;
$form-background-invert-hover: transparent !default;
$form-background-invert-selected: transparent !default;
$form-background-invert-error: transparent !default;

/* Field Shadow Styles */
$form-shadow: none !default;
$form-shadow-hover: none !default;
$form-shadow-active: 0 3px 0 0 rgba(color(secondary), .5) !default;
$form-shadow-error: 0 3px 0 0 rgba(color(extra, error), .3) !default;
$form-shadow-invert: none !default;
$form-shadow-invert-hover: none !default;
$form-shadow-invert-active: 0 3px 0 0 rgba(color(secondary), .2) !default;
$form-shadow-invert-error: 0 3px 0 0 rgba(color(extra, error), .5) !default;

/* Placeholder Colors */
$form-placeholder-color: $color-base-light !default;
$form-placeholder-color-invert: $color-base-invert-light !default;

/* Text Colors */
$form-text-color: $color-base !default;
$form-text-color-invert: $color-base-invert !default;

/* Icon Styles */
$form-icon-color: color(neutral, 500) !default;
$form-icon-color-invert: color(neutral, 500) !default;



/* Style - Fill
   ========================================================================== */

/* Field Styles */
$form-fill-field-border-radius: $border-radius !default;

/* Field Border Colors */
$form-fill-border-color: $color-border-base !default;
$form-fill-border-color-hover: $color-border-base-darker !default;
$form-fill-border-color-selected: color(secondary) !default;
$form-fill-border-color-error: color(extra, error) !default;
$form-fill-border-color-invert: $color-border-base !default;
$form-fill-border-color-invert-hover: $color-border-base !default;
$form-fill-border-color-invert-selected: color(secondary) !default;
$form-fill-border-color-invert-error: color(extra, error) !default;

/* Field Background Colors */
$form-fill-background: rgba(255, 255, 255, 1) !default;
$form-fill-background-hover: rgba(245, 245, 245, .9) !default;
$form-fill-background-selected: rgba(245, 245, 245, .9) !default;
$form-fill-background-error: rgba(245, 245, 245, .9) !default;
$form-fill-background-invert: rgba(255, 255, 255, .9) !default;
$form-fill-background-invert-hover: rgba(255, 255, 255, 1) !default;
$form-fill-background-invert-selected: $form-fill-background-invert-hover !default;
$form-fill-background-invert-error: $form-fill-background-invert !default;

/* Field Shadow Styles */
$form-fill-shadow: none !default;
$form-fill-shadow-hover: none !default;
$form-fill-shadow-active: $shadow-blue-level-5 !default;
$form-fill-shadow-error: none !default;
$form-fill-shadow-invert: none !default;
$form-fill-shadow-invert-hover: none !default;
$form-fill-shadow-invert-active: $shadow-blue-level-5 !default;
$form-fill-shadow-invert-error: none !default;

/* Placeholder Colors */
$form-fill-placeholder-color: $color-base-light !default;
$form-fill-placeholder-color-invert: $color-base-light !default;

/* Text Colors */
$form-fill-text-color: $color-accent !default;
$form-fill-text-color-invert: $color-accent !default;

/* Icon Styles */
$form-fill-icon-color: color(neutral, 700) !default;
$form-fill-icon-color-invert: color(neutral, 700) !default;



/* Text field style mixin
   ========================================================================== */

@mixin input-text {

	input[type="text"],
	input[type="password"],
	input[type="time"],
	input[type="timestamp"],
	input[type="date"],
	input[type="datetime-local"],
	input[type="month"],
	input[type="week"],
	input[type="email"],
	input[type="number"],
	input[type="search"],
	input[type="tel"],
	input[type="url"],
	input[type="file"],
	select,
	textarea {
		@content;
	}
}