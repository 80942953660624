/* =============================================================================
   GRID (objects/_grid.scss)
   ========================================================================== */

/**
 * Flexbox based grid.
 */

/* Container
   ========================================================================== */

.o-container-fluid {
	position: relative;
	max-width: $site-width;
}
.o-container {
	position: relative;
	max-width: $site-width;

	&--xs {
		max-width: rem(440px) + $grid-gutter;
	}
	&--sm {
		max-width: map-get($breakpoints, 'sm') + $grid-gutter;
	}
	&--md {
		max-width: map-get($breakpoints, 'md') + $grid-gutter;
	}
	&--lg {
		max-width: map-get($breakpoints, 'lg') + $grid-gutter;
	}
	&--xl {
		max-width: map-get($breakpoints, 'xl');
	}
	&--full {
		max-width: none;
		@include media-breakpoint-up(lg) {
			padding-left: $horizontal-spacer-large;
			padding-right: $horizontal-spacer-large;
		}
	}
}



/* Row
   ========================================================================== */
.o-row--no-margin {
	margin-left: 0;
	margin-right: 0;
}

.o-row--spaced {
    gap: 1rem;
}

.o-row--align-right {
	align-items: center;
    justify-content: flex-end;
}

@each $breakpoint-key, $breakpoint-value in $breakpoints {
	@if $breakpoint-key == xs {
		.o-row--reverse {
			flex-direction: row-reverse;
		}
		.o-row--small-gutters {
			margin-left: - $grid-gutter / 4;
			margin-right: - $grid-gutter / 4;

			> [class*="o-col"] {
				padding-left: $grid-gutter / 4;
				padding-right: $grid-gutter / 4;
			}
		}
		.o-row--fluid {
			.o-col {
				flex: 0 1 auto;
				width: auto;
			}
		}
	} @else {
		@include media-breakpoint-up(#{$breakpoint-key}) {
			.o-row--reverse\@#{$breakpoint-key} {
				flex-direction: row-reverse;
			}
			.o-row--small-gutters\@#{$breakpoint-key} {
				margin-left: - $grid-gutter / 4;
				margin-right: - $grid-gutter / 4;

				> [class*="o-col"] {
					padding-left: $grid-gutter / 4;
					padding-right: $grid-gutter / 4;
				}
			}
			.o-row--fluid\@#{$breakpoint-key} {
				.o-col {
					flex: 0 1 auto;
					width: auto;
				}
			}
		}
	}
}