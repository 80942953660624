
.c-offcanvas {
	height: 100%;
	padding-top: $offcanvas-offset-top-mobile;
	transform: translateY(-10vh);
	@include media-breakpoint-up(md) {
		padding-top: $offcanvas-offset-top;
	}
}

.c-offcanvas__inner {
	position: absolute;
	top: $offcanvas-offset-top-mobile;
	right: 0;
	bottom: 0;
	left: 0;
	height: 100%;
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
	padding-top: $spacer-base-large;
	padding-bottom: $spacer-base-large - $spacer-base;
	@include media-breakpoint-up(md) {
		top: $offcanvas-offset-top;
	}
}
