/* =============================================================================
   WORD-WRAP (utilities/_word-wrap.scss)
   ========================================================================== */

@mixin word-wrap() {
	overflow-wrap: break-word;
	word-wrap: break-word;
	-ms-word-break: break-all;
	word-break: break-word;
}
