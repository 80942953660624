
/* Mixins
   ========================================================================== */

@mixin link-default {
	font-weight: $font-weight-accent;
	color: $color-links;
	cursor: pointer;
	transition: all $transition-speed;

	&:hover {
		color: $color-links-darker;
	}

	.c-color--invert & {
		color: $color-links-lighter;

		&:hover {
			color: lighten($color-links-lighter, $color-shade-percentage);
		}
	}
	.c-color--white & {
		color: $color-accent-invert;

		&:hover {
			color: lighten($color-accent-invert, $color-shade-percentage);
		}
	}
	.c-color--default & {
		color: $color-links;

		&:hover {
			color: $color-links-darker;
		}
	}
}

@mixin link-basic-light {
	font-weight: $font-weight-accent;
	color: $color-base-light;
	cursor: pointer;
	transition: all $transition-speed;

	&:hover {
		color: $color-accent;
	}

	.c-color--invert & {
		color: $color-base-invert-light;

		&:hover {
			color: $color-accent-invert;
		}
	}
	.c-color--white & {
		color: $color-base-invert-light;

		&:hover {
			color: lighten($color-accent-invert, $color-shade-percentage);
		}
	}
	.c-color--default & {
		color: $color-base-light;

		&:hover {
			color: $color-accent;
		}
	}
}

@mixin link-basic {
	font-weight: $font-weight-accent;
	color: $color-accent;
	cursor: pointer;
	transition: all $transition-speed;

	&:hover {
		color: $color-links;
	}

	.c-color--invert & {
		color: $color-accent-invert;

		&:hover {
			color: $color-links;
		}
	}
	.c-color--white & {
		color: $color-accent-invert;

		&:hover {
			color: lighten($color-accent-invert, $color-shade-percentage);
		}
	}
	.c-color--default & {
		color: $color-accent;

		&:hover {
			color: $color-links;
		}
	}
}

@mixin link-underline {
	position: relative;
	z-index: 2;
	font-weight: $font-weight-accent;
	background: linear-gradient(to left, rgba($color-links, .1) 50%, rgba($color-links, .4) 50%);
	background-position: 99% 1.02em;
	background-size: 210% 0.5em;
	background-repeat: no-repeat;
	cursor: pointer;
	transition: all $transition-speed;

	&:hover {
		background-position-x: 0%;
	}

	.c-color--invert & {
		background-image: linear-gradient(to left, rgba($color-links-darker, .5) 50%, rgba($color-links-lighter, .6) 50%);
	}
	.c-color--white & {
		background-image: linear-gradient(to left, rgba($color-background-base, .2) 50%, rgba($color-background-base, .5) 50%);
	}
	.c-color--default & {
		background: linear-gradient(to left, rgba($color-links, .1) 50%, rgba($color-links, .4) 50%);
	}
}

@mixin link-cta-light {
	display: inline-flex;
	align-items: center;
	font-weight: $button-font-weight;
	font-size: $button-text-size;
	color: $color-base-light;
	cursor: pointer;
	transition: all $transition-speed;

	span {
		position: relative;

		&:before {
			content: '';
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			height: rem(2px);
			background: linear-gradient(to left, transparent 50%, rgba(color(primary), 1) 50%);
			background-position: 99% 0;
			background-size: 210% 100%;
			background-repeat: no-repeat;
			border-radius: rem(1px);
			transition: all $transition-speed;
		}

		+ .o-svg-icon {
			margin-left: 0.5em;
		}
	}
	.o-svg-icon {
		left: 0;
		color: $color-accent;
		opacity: .35;
		transition: left $transition-speed, opacity $transition-speed;

		+ span {
			margin-left: 0.5em;
		}
	}

	&:hover {
		color: $color-accent;

		.o-svg-icon {
			opacity: .85;

			&.o-svg-left {
				left: -0.25em;
			}
		}
		span {
			&:before {
				background-position-x: 0%;
			}
			+ .o-svg-left {
				left: 0;
			}
			+ .o-svg-right {
				left: 0.25em;
			}
		}
	}

	.c-color--invert &,
	.c-color--white & {
		color: $color-base-invert-light;

		span {
			&:before {
				background-image: linear-gradient(to left, transparent 50%, rgba(color(secondary), 1) 50%);
			}
		}
		.o-svg-icon {
			color: $color-accent-invert;
		}
		&:hover {
			color: $color-accent-invert;
		}
	}
	.c-color--default & {
		color: $color-base-light;

		span {
			&:before {
				background-image: linear-gradient(to left, transparent 50%, rgba(color(primary), 1) 50%);
			}
		}
		.o-svg-icon {
			color: $color-accent;
		}
		&:hover {
			color: $color-accent;
		}
	}
}
@mixin link-cta-basic {
	@include link-cta-light;
	color: $color-base;

	&:hover {
		color: $color-accent;
	}
	.c-color--invert &,
	.c-color--white & {
		color: $color-base-invert;

		&:hover {
			color: $color-accent-invert;
		}
	}
	.c-color--default & {
		color: $color-base;

		&:hover {
			color: $color-accent;
		}
	}

}
@mixin link-cta {
	@include link-cta-basic;

	span {
		&:before {
			background-image: linear-gradient(to left, rgba(color(secondary), .8) 50%, rgba(color(primary), 1) 50%);
		}
	}
	.c-color--invert &,
	.c-color--white & {
		span {
			&:before {
				background-image: linear-gradient(to left, rgba($color-background-base, .8) 50%, rgba(color(secondary), 1) 50%);
			}
		}
	}
	.c-color--default & {
		span {
			&:before {
				background-image: linear-gradient(to left, rgba(color(secondary), .8) 50%, rgba(color(primary), 1) 50%);
			}
		}
	}
}
@mixin link-cta--small {
	font-size: $button-text-size-small;
}
@mixin link-cta--large {
	font-size: $button-text-size-large;
}




/* Link classes
   ========================================================================== */

a {
	@include link-default;
}
.c-link-basic-light {
	@extend a;
	@include link-basic-light;
}
.c-link-basic {
	@extend a;
	@include link-basic;
}
.c-link-underline {
	@extend a;
	@include link-underline;
}
.c-link-cta {
	@extend a;
	@include link-cta;
}
.c-link-cta-basic {
	@extend a;
	@include link-cta-basic;
}
.c-link-cta-light {
	@extend a;
	@include link-cta-light;
}
.c-link-cta,
.c-link-cta-basic,
.c-link-cta-light {
	&--small {
		@include link-cta--small;
	}
	&--large {
		@include link-cta--large;
	}
}
