
address,
blockquote,
dl,
fieldset,
figure,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6,
hr,
body ol,
body ul,
body p,
pre,
body table,
body form,
iframe,
embed,
object {
	margin-bottom: $spacer-base;
}
