
.c-wysiwyg {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		&:not(:first-child) {
			margin-top: $spacer-base-large;
		}
	}
	p,
	li,
	td,
	th,
	code {
		a {
			&:not(.c-image-container) {
				@include link-underline;
			}
		}
	}
	td,
	th {
		font-size: font-size(03);
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	li,
	code {
		@include word-wrap;
	}
	blockquote {
		/*padding: $spacer-base;
		padding-bottom: $spacer-base - $spacer-base-small;
		background: rgba(color(primary), .1);

		> p {
			font-size: font-size(05);
			font-style: italic;
		}
		cite {
			> p {
				font-size: font-size(03);
			}
		}*/
	}

	.wp-caption,
	.c-gallery-slider,
	.c-article__image-container,
	table,
	blockquote {
		margin-bottom: $spacer-base-large;

		&.alignleft {
			@include media-breakpoint-up(sm) {
				margin-bottom: $spacer-base;
			}
		}
		&.alignright {
			@include media-breakpoint-up(sm) {
				margin-bottom: $spacer-base;
			}
		}
	}
	.wp-caption,
	.c-gallery-slider,
	.c-article__image-container,
	.c-table-wrapper,
	table,
	blockquote {
		&:not(:first-child) {
			margin-top: $spacer-base-large;

			&.alignleft {
				@include media-breakpoint-up(sm) {
					margin-top: 0;
				}
			}
			&.alignright {
				@include media-breakpoint-up(sm) {
					margin-top: 0;
				}
			}
		}
	}

	.c-small-text {
		p,
		li {
			font-size: font-size(03);
		}
	}
	.wp-caption {
		max-width: 100%;

		.wp-caption-text {
			@extend .c-caption;
			position: relative;
			margin: 0;
			padding-top: $spacer-base-small;
			color: $color-base-light;
		}
	}


	.alignleft {
		@include media-breakpoint-up(sm) {
			float: left;
			margin-right: $spacer-base;
			max-width: 50%;
		}
		@include media-breakpoint-up(xl) {
			margin-right: $spacer-base + $spacer-base-small;
			max-width: 45%;
		}
	}
	.alignright {
		@include media-breakpoint-up(sm) {
			float: right;
			margin-left: $spacer-base;
			max-width: 50%;
		}
		@include media-breakpoint-up(xl) {
			margin-left: $spacer-base + $spacer-base-small;
			max-width: 45%;
		}
	}



	ul,
	ol {
		margin-left: 1.5rem;
		margin-bottom: $spacer-base;
	}
	li {
		position: relative;
		margin-bottom: $spacer-base-small;
		list-style-position: outside;
	}
	li ol,
	li ul {
		margin-top: $spacer-base-small;
	}

	ul > li {
		&:before {
			content: '';
			position: absolute;
			left: - $spacer-base;
			top: 0.75em;
			transform: translate(0, -50%);
			width: rem(8px);
			height: rem(8px);
			background: rgba(color(primary), 1);
			border-radius: 50%;
		}
	}
	ol ul > li {
	   list-style-type: disc !important;
	   &:before {
		   display: none !important;
	   }
	}
	ul li li {
	   list-style-type: disc;
	   &:before {
		   display: none;
	   }
	}
	ul li li li {
	   list-style-type: circle;
	}
	ul li li li li {
	   list-style-type: square;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	div,
	ul,
	ol,
	li,
	blockquote {
		+ ol,
		> ol {
			> li {
			}
		}
	}
	ol {
		counter-reset: counter;
	}
	ol > li {
		counter-increment: counter;
		list-style-type: none !important;
		&:before {
			content: counter( counter );
			display: block !important;
			position: absolute;
			left: - $spacer-base;
			top: 0.125em;
			font-size: 0.875em;
			font-weight: bold;
			color: rgba(color(primary), 1);
		}
	}
	ol li li {
		&:before {
			font-weight: normal;
			color: inherit;
			opacity: .55;
		}
	}

	.c-page-footer & {
		p,
		li {
			color: $color-base;
		}
	}
	.c-page-footer .c-color--invert & {
		p,
		li {
			color: #fff;
		}
	}


	article & {
		@include media-breakpoint-up(lg) {
			font-size: font-size(base);
		}
	}

}
