/* =============================================================================
   TAGS (components/_tags.scss)
   ========================================================================== */

.c-tag {
	display: inline-block;
	min-width: $tag-height;
	padding: $tag-padding-horizontal;
	padding-top: ($tag-height - ($tag-text-size * $line-height-medium) - (2 * $tag-border-width)) / 2;
	padding-bottom: ($tag-height - ($tag-text-size * $line-height-medium) - (2 * $tag-border-width)) / 2;
	line-height: $line-height-medium;
	font-size: $tag-text-size;
	font-weight: $tag-font-weight;
	color: $color-base;
	text-align: center;
	background: transparent;
	border: $tag-border-width solid transparent;
	border-radius: $tag-border-radius;
	box-shadow: $tag-shadow;
	transition: all $transition-speed;

	@at-root a#{&}, button#{&}, &.c-tag--link {
		&:hover {
			box-shadow: $tag-shadow-hover;
		}
	}
	&.c-tag--stroke {
		box-shadow: $tag-stroke-shadow;

		@at-root a#{&}, button#{&}, &.c-tag--link {
			&:hover {
				box-shadow: $tag-stroke-shadow-hover;
			}
		}
	}
	.c-color--invert & {
		box-shadow: $tag-shadow-invert;

		@at-root a#{&}, button#{&}, &.c-tag--link {
			&:hover {
				box-shadow: $tag-shadow-invert-hover;
			}
		}
		&.c-tag--stroke {
			box-shadow: $tag-stroke-shadow-invert;

			@at-root a#{&}, button#{&}, &.c-tag--link {
				&:hover {
					box-shadow: $tag-stroke-shadow-invert-hover;
				}
			}
		}
	}
}

.c-tag--small {
	min-width: $tag-height-small;
	padding: $tag-padding-horizontal-small;
	padding-top: ($tag-height-small - ($tag-text-size-small * $line-height-medium) - (2 * $tag-border-width)) / 2;
	padding-bottom: ($tag-height-small - ($tag-text-size-small * $line-height-medium) - (2 * $tag-border-width)) / 2;
	font-size: $tag-text-size-small;
	border-radius: $tag-border-radius;
}
.c-tag--large {
	min-width: $tag-height-large;
	padding: $tag-padding-horizontal-large;
	padding-top: ($tag-height-large - ($tag-text-size-large * $line-height-medium) - (2 * $tag-border-width)) / 2;
	padding-bottom: ($tag-height-large - ($tag-text-size-large * $line-height-medium) - (2 * $tag-border-width)) / 2;
	font-size: $tag-text-size-large;
	border-radius: $tag-border-radius-large;
}

.c-tag--link {
	cursor: pointer;
	transition: all $transition-speed;
}



/* Button Styles - Default
   ========================================================================== */

.c-tag {
	color: $tag-color;
	background-color: $tag-background;
	border-color: $tag-border-color;

	@at-root a#{&}, button#{&}, &.c-tag--link {
		&:hover {
			color: $tag-color-hover;
			background-color: $tag-background-hover;
			border-color: $tag-border-color-hover;
		}
	}
	&.c-tag--stroke {
		color: $tag-stroke-color;
		background-color: $tag-stroke-background;
		border-color: $tag-stroke-border-color;

		@at-root a#{&}, button#{&}, &.c-tag--link {
			&:hover {
				color: $tag-stroke-color-hover;
				background-color: $tag-stroke-background-hover;
				border-color: $tag-stroke-border-color-hover;
			}
		}
	}
	.c-color--invert & {
		color: $tag-color-invert;
		background-color: $tag-background-invert;
		border-color: $tag-border-color-invert;

		@at-root a#{&}, button#{&}, &.c-tag--link {
			&:hover {
				color: $tag-color-invert-hover;
				background-color: $tag-background-invert-hover;
				border-color: $tag-border-color-invert-hover;
			}
		}
		&.c-tag--stroke {
			color: $tag-stroke-color-invert;
			background-color: $tag-stroke-background-invert;
			border-color: $tag-stroke-border-color-invert;

			@at-root a#{&}, button#{&}, &.c-tag--link {
				&:hover {
					color: $tag-stroke-color-invert-hover;
					background-color: $tag-stroke-background-invert-hover;
					border-color: $tag-stroke-border-color-invert-hover;
				}
			}
		}
	}
}

/* Button Styles - White
   ========================================================================== */

.c-tag--white {
	color: $tag-white-color;
	background-color: $tag-white-background;
	border-color: $tag-white-border-color;

	@at-root a#{&}, button#{&}, &.c-tag--link {
		&:hover {
			color: $tag-white-color-hover;
			background-color: $tag-white-background-hover;
			border-color: $tag-white-border-color-hover;
		}
	}
	&.c-tag--stroke {
		color: $tag-white-stroke-color;
		background-color: $tag-white-stroke-background;
		border-color: $tag-white-stroke-border-color;

		@at-root a#{&}, button#{&}, &.c-tag--link {
			&:hover {
				color: $tag-white-stroke-color-hover;
				background-color: $tag-white-stroke-background-hover;
				border-color: $tag-white-stroke-border-color-hover;
			}
		}
	}
	.c-color--invert & {
		color: $tag-white-color-invert;
		background-color: $tag-white-background-invert;
		border-color: $tag-white-border-color-invert;

		@at-root a#{&}, button#{&}, &.c-tag--link {
			&:hover {
				color: $tag-white-color-invert-hover;
				background-color: $tag-white-background-invert-hover;
				border-color: $tag-white-border-color-invert-hover;
			}
		}
		&.c-tag--stroke {
			color: $tag-white-stroke-color-invert;
			background-color: $tag-white-stroke-background-invert;
			border-color: $tag-white-stroke-border-color-invert;

			@at-root a#{&}, button#{&}, &.c-tag--link {
				&:hover {
					color: $tag-white-stroke-color-invert-hover;
					background-color: $tag-white-stroke-background-invert-hover;
					border-color: $tag-white-stroke-border-color-invert-hover;
				}
			}
		}
	}
}

/* Button Styles - Primary
   ========================================================================== */

.c-tag--primary {
	color: $tag-primary-color;
	background-color: $tag-primary-background;
	border-color: $tag-primary-border-color;

	@at-root a#{&}, button#{&}, &.c-tag--link {
		&:hover {
			color: $tag-primary-color-hover;
			background-color: $tag-primary-background-hover;
			border-color: $tag-primary-border-color-hover;
		}
	}
	&.c-tag--stroke {
		color: $tag-primary-stroke-color;
		background-color: $tag-primary-stroke-background;
		border-color: $tag-primary-stroke-border-color;

		@at-root a#{&}, button#{&}, &.c-tag--link {
			&:hover {
				color: $tag-primary-stroke-color-hover;
				background-color: $tag-primary-stroke-background-hover;
				border-color: $tag-primary-stroke-border-color-hover;
			}
		}
	}
	.c-color--invert & {
		color: $tag-primary-color-invert;
		background-color: $tag-primary-background-invert;
		border-color: $tag-primary-border-color-invert;

		@at-root a#{&}, button#{&}, &.c-tag--link {
			&:hover {
				color: $tag-primary-color-invert-hover;
				background-color: $tag-primary-background-invert-hover;
				border-color: $tag-primary-border-color-invert-hover;
			}
		}
		&.c-tag--stroke {
			color: $tag-primary-stroke-color-invert;
			background-color: $tag-primary-stroke-background-invert;
			border-color: $tag-primary-stroke-border-color-invert;

			@at-root a#{&}, button#{&}, &.c-tag--link {
				&:hover {
					color: $tag-primary-stroke-color-invert-hover;
					background-color: $tag-primary-stroke-background-invert-hover;
					border-color: $tag-primary-stroke-border-color-invert-hover;
				}
			}
		}
	}
}

/* Button Styles - Secondary
   ========================================================================== */

.c-tag--secondary {
	color: $tag-secondary-color;
	background-color: $tag-secondary-background;
	border-color: $tag-secondary-border-color;

	@at-root a#{&}, button#{&}, &.c-tag--link {
		&:hover {
			color: $tag-secondary-color-hover;
			background-color: $tag-secondary-background-hover;
			border-color: $tag-secondary-border-color-hover;
		}
	}
	&.c-tag--stroke {
		color: $tag-secondary-stroke-color;
		background-color: $tag-secondary-stroke-background;
		border-color: $tag-secondary-stroke-border-color;

		@at-root a#{&}, button#{&}, &.c-tag--link {
			&:hover {
				color: $tag-secondary-stroke-color-hover;
				background-color: $tag-secondary-stroke-background-hover;
				border-color: $tag-secondary-stroke-border-color-hover;
			}
		}
	}
	.c-color--invert & {
		color: $tag-secondary-color-invert;
		background-color: $tag-secondary-background-invert;
		border-color: $tag-secondary-border-color-invert;

		@at-root a#{&}, button#{&}, &.c-tag--link {
			&:hover {
				color: $tag-secondary-color-invert-hover;
				background-color: $tag-secondary-background-invert-hover;
				border-color: $tag-secondary-border-color-invert-hover;
			}
		}
		&.c-tag--stroke {
			color: $tag-secondary-stroke-color-invert;
			background-color: $tag-secondary-stroke-background-invert;
			border-color: $tag-secondary-stroke-border-color-invert;

			@at-root a#{&}, button#{&}, &.c-tag--link {
				&:hover {
					color: $tag-secondary-stroke-color-invert-hover;
					background-color: $tag-secondary-stroke-background-invert-hover;
					border-color: $tag-secondary-stroke-border-color-invert-hover;
				}
			}
		}
	}
}


/* Tag Styles - Error
   ========================================================================== */

.c-tag--error {
	color: $tag-error-color;
	background-color: $tag-error-background;
	border-color: $tag-error-border-color;

	@at-root a#{&}, button#{&}, &.c-tag--link {
		&:hover {
			color: $tag-error-color-hover;
			background-color: $tag-error-background-hover;
			border-color: $tag-error-border-color-hover;
		}
	}
	.c-color--invert & {
		color: $tag-error-color-invert;
		background-color: $tag-error-background-invert;
		border-color: $tag-error-border-color-invert;
	}
	@at-root a#{&}, button#{&}, &.c-tag--link {
		.c-color--invert & {
			&:hover {
				color: $tag-error-color-invert-hover;
				background-color: $tag-error-background-invert-hover;
				border-color: $tag-error-border-color-invert-hover;
			}
		}
	}
	&.c-tag--stroke {
		color: $tag-error-stroke-color;
		background-color: $tag-error-stroke-background;
		border-color: $tag-error-stroke-border-color;

		@at-root a#{&}, button#{&}, &.c-tag--link {
			&:hover {
				color: $tag-error-stroke-color-hover;
				background-color: $tag-error-stroke-background-hover;
				border-color: $tag-error-stroke-border-color-hover;
			}
		}
		.c-color--invert & {
			&.c-tag--stroke {
				color: $tag-error-stroke-color-invert;
				background-color: $tag-error-stroke-background-invert;
				border-color: $tag-error-stroke-border-color-invert;
			}
		}
		@at-root a#{&}, button#{&}, &.c-tag--link {
			.c-color--invert & {
				&:hover {
					color: $tag-error-stroke-color-invert-hover;
					background-color: $tag-error-stroke-background-invert-hover;
					border-color: $tag-error-stroke-border-color-invert-hover;
				}
			}
		}

		.o-svg-icon {
			color: color(extra,error);
		}
	}
}



/* Tag Icons
   ========================================================================== */

.c-tag {
	.o-svg-icon {
		top: - (($tag-text-size * $line-height-medium) - $tag-icon-size) / 2;
		margin-top: (($tag-text-size * $line-height-medium) - $tag-icon-size);
		margin-right: 0.5em;
		font-size: $tag-icon-size;
		opacity: $tag-icon-opacity;
		transition: opacity $transition-speed;
	}
	span {
		+ .o-svg-icon {
			margin-right: 0;
			margin-left: 0.5em;
		}
	}

	&:hover {
		.o-svg-icon {
			opacity: $tag-icon-opacity-hover;
		}
	}

	&.c-tag--small {
		.o-svg-icon {
			top: - (($tag-text-size-small * $line-height-medium) - $tag-icon-size-small) / 2;
			margin-top: (($tag-text-size-small * $line-height-medium) - $tag-icon-size-small);
			font-size: $tag-icon-size-small;
		}
	}
	&.c-tag--large {
		.o-svg-icon {
			top: - (($tag-text-size-large * $line-height-medium) - $tag-icon-size-large) / 2;
			margin-top: (($tag-text-size-large * $line-height-medium) - $tag-icon-size-large);
			font-size: $tag-icon-size-large;
		}
	}
}
