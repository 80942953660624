/* =============================================================================
   SVG ICONS (objects/_svg-icons.scss)
   ========================================================================== */

.o-svg-icon {
	/* Flow with text content */
    position: relative;
	display: inline-block;
	height: 1em;
    width: 1em;

	/* Inherit the parent text color */
	fill: currentColor;

	/* Vertically align icon with adjacent text */
	vertical-align: top;

	&:hover,
	&:focus {
		fill: currentColor;
	}
}


/* Rotate icons
   ========================================================================== */

.o-svg-up {
	/* default */
	transform: rotate(0deg);
}
.o-svg-right {
	transform: rotate(90deg);
}
.o-svg-down {
	transform: rotate(180deg);
}
.o-svg-left {
	transform: rotate(-90deg);
}


/* Change size
   ========================================================================== */

.o-svg-large {
	height: 1.5em;
	width: 1.5em;
}
.o-svg-larger {
	height: 1.25em;
	width: 1.25em;
}
.o-svg-smaller {
	height: .75em;
	width: .75em;
}
.o-svg-small {
	height: .5em;
	width: .5em;
}
