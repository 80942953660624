
.c-loading-dashboard {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 80vh;
	padding-top: $spacer-base;
	margin-bottom: $spacer-base;
	background: $color-background-light;
	border-radius: $border-radius-large;
	overflow: hidden;

	> * {
		position: relative;
		z-index: 5;
	}
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: $border-radius-large;
		transform: translateX(-100%);
		background: linear-gradient(70deg, $color-background-light 0%, $color-background-light 10%, $color-background-base 40%, $color-background-base 60%, $color-background-light 90%, $color-background-light 100%);
		animation: loadingSkeleton $animation-speed infinite;
	}
	.c-site-logo {
		margin-bottom: $spacer-base;
	}
	.c-loading-bar {
		margin-bottom: $spacer-base;
		width: rem(240px);
	}

	.c-theme--dark & {
		background: $color-background-light-invert;

		&:before {
			background: linear-gradient(70deg, $color-background-light-invert 0%, $color-background-light-invert 10%, $color-background-invert 40%, $color-background-invert 60%, $color-background-light-invert 90%, $color-background-light-invert 100%);
		}
	}
}

.c-loading-bar {
	position: relative;
	width: 100%;

	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		height: rem(6px);
		border-radius: rem(3px);
		background: $color-background-light;
		border: 2px solid $color-border-base;
	}

	.c-loading-bar__shape {
		position: relative;
		z-index: 5;
		width: 0;
		height: rem(6px);
		border-radius: rem(3px);
		background: color(primary);
		box-shadow: $shadow-blue-level-6;
		transition: all $transition-speed;
	}

	.c-theme--dark & {
		&:before {
			background: $color-background-light-invert;
			border-color: $color-border-invert;
		}
		.c-loading-bar__shape {
			background: color(secondary);
			box-shadow: $shadow-level-7-invert;
		}
	}
}

.c-load-page {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10000;
	overflow: hidden;
	animation-name: circleLoadingPage;
	animation-duration: $animation-speed-faster;
	animation-timing-function: ease-in-out;
	animation-fill-mode: forwards;

	.c-load-page__overlay {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 200vw;
		height: 200vw;
		margin-top: - 100vw;
		margin-left: - 100vw;
		border-radius: 50%;
		background-color: color(primary);
		animation-name: circleLoadingHalf;
		animation-duration: $animation-speed-faster;
		animation-timing-function: ease-in-out;
		animation-fill-mode: forwards;
		@media screen and (orientation:portrait) {
			width: 200vh;
			height: 200vh;
			margin-top: - 100vh;
			margin-left: - 100vh;
		}

		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			width: rem(200px);
			height: rem(200px);
			margin-top: - rem(100px);
			margin-left: - rem(100px);
			border-radius: 50%;
			background-color: #888;
			opacity: 0.2;
			animation-name: buttonLoading;
			animation-duration: $animation-speed;
			animation-iteration-count: infinite;
			animation-timing-function: ease-in-out;
		}
		&:after {
			opacity: .8;
			background-color: lighten(color(primary), (2 * $color-shade-percentage));
			animation-delay: -1.0s;
		}
	}
	.c-load-page__body {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 10;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding-left: $grid-gutter / 2;
		padding-right: $grid-gutter / 2;
	}

	&.c-load-page--closing {
		animation-name: circleLoadingPageReverse;
		animation-duration: $animation-speed-faster;
		animation-timing-function: ease-in-out;
		animation-fill-mode: forwards;

		.c-load-page__overlay {
			animation-name: circleLoadingHalfReverse;
			animation-duration: $animation-speed-faster;
			animation-timing-function: ease-in-out;
			animation-fill-mode: forwards;
		}
	}
}
