
.c-page-message__wrapper {
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.c-page-message {
	margin-bottom: $spacer-base;
	text-align: center;

	p {
		margin-bottom: $spacer-base-small;
		color: $color-base-lighter;

		.c-color--invert & {
			color: $color-base-invert-lighter;
		}
	}
	.c-page-message__figure {
		margin-bottom: $spacer-base;

		.o-svg-icon {
			opacity: .6;
			font-size: rem(128px);
		}
	}
	.c-page-message__title {
		margin-bottom: $spacer-base;
		font-weight: $font-weight-accent;
		font-size: font-size(h6);
		line-height: $line-height-medium;
		color: $color-base-light;

		.c-color--invert & {
			color: $color-base-invert;
		}
	}
}
