$shadow-level-1: 0 1px 2px rgba(0, 0, 0, 0.1) !default;
$shadow-level-2: 0 1px 4px -1px rgba(0, 0, 0, 0.1),
  0 1px 3px rgba(0, 0, 0, 0.05) !default;
$shadow-level-3: 0 1px 6px -2px rgba(0, 0, 0, 0.1),
  0 1px 5px rgba(0, 0, 0, 0.05) !default;
$shadow-level-4: 0 1px 8px -2px rgba(0, 0, 0, 0.1),
  0 2px 5px rgba(0, 0, 0, 0.05) !default;
$shadow-level-5: 0 2px 10px -2px rgba(0, 0, 0, 0.1),
  0 2px 10px rgba(0, 0, 0, 0.05) !default;
$shadow-level-6: 0 4px 12px -2px rgba(0, 0, 0, 0.1),
  0 2px 20px rgba(0, 0, 0, 0.05) !default;
$shadow-level-7: 0 6px 15px -2px rgba(0, 0, 0, 0.1),
  0 2px 30px rgba(0, 0, 0, 0.05) !default;

$shadow-level-2-invert: 0 1px 4px -1px rgba(0, 0, 0, 0.5),
  0 1px 3px rgba(0, 0, 0, 0.1) !default;
$shadow-level-3-invert: 0 1px 6px -2px rgba(0, 0, 0, 0.5),
  0 1px 5px rgba(0, 0, 0, 0.1) !default;
$shadow-level-4-invert: 0 1px 8px -2px rgba(0, 0, 0, 0.5),
  0 2px 5px rgba(0, 0, 0, 0.1) !default;
$shadow-level-5-invert: 0 2px 10px -2px rgba(0, 0, 0, 0.3),
  0 2px 10px rgba(0, 0, 0, 0.1) !default;
$shadow-level-6-invert: 0 4px 12px -2px rgba(0, 0, 0, 0.3),
  0 2px 20px rgba(0, 0, 0, 0.1) !default;
$shadow-level-7-invert: 0 6px 15px -2px rgba(0, 0, 0, 0.3),
  0 2px 30px rgba(0, 0, 0, 0.1) !default;

$shadow-blue-level-1: 0 1px 2px rgba(color(primary), 0.1) !default;
$shadow-blue-level-2: 0 1px 4px -1px rgba(color(primary), 0.1),
  0 1px 3px rgba(0, 0, 0, 0.05) !default;
$shadow-blue-level-3: 0 1px 6px -2px rgba(color(primary), 0.1),
  0 1px 5px rgba(0, 0, 0, 0.05) !default;
$shadow-blue-level-4: 0 1px 8px -2px rgba(color(primary), 0.1),
  0 2px 5px rgba(0, 0, 0, 0.05) !default;
$shadow-blue-level-5: 0 2px 10px -2px rgba(color(primary), 0.1),
  0 2px 10px rgba(0, 0, 0, 0.05) !default;
$shadow-blue-level-6: 0 4px 12px -2px rgba(color(primary), 0.1),
  0 2px 20px rgba(0, 0, 0, 0.05) !default;
$shadow-blue-level-7: 0 6px 15px -2px rgba(color(primary), 0.1),
  0 2px 30px rgba(0, 0, 0, 0.05) !default;

$shadow-green-level-3: 0 1px 6px -2px rgba(color(extra, success), 0.5),
  0 1px 5px rgba(color(extra, success), 0.5) !default;
$shadow-green-level-6: 0 4px 12px -2px rgba(color(extra, success), 0.5),
  0 2px 20px 5px rgba(0, 0, 0, 0.05) !default;

$shadow-card: 0 2px 10px rgba(0, 0, 0, 0.05) !default;
$shadow-table: -3px 2px 4px rgba(0, 0, 0, 0.03), 3px 2px 4px rgba(0, 0, 0, 0.03) !default;

$shadow-popup-item: 0px 0px 5px 1px rgba(color(extra, links), 0.75) !default;
