
.c-filters-sticky {
	@include media-breakpoint-up(sm) {
		position: sticky;
		top: 0;
		z-index: 12;
		margin-left: - $grid-gutter / 2;
		margin-right: - $grid-gutter / 2;
		padding-left: $grid-gutter / 2;
		padding-right: $grid-gutter / 2;
		background: $color-background-base;
	}
}
