/* =============================================================================
   TYPOGRAPHY (utilities/_typography.scss)
   ========================================================================== */


/* Text Color
   ========================================================================== */

@each $breakpoint-key, $breakpoint-value in $breakpoints {
	@if $breakpoint-key == xs {
		.u-text-accent {
			color: $color-accent !important;
			.c-color--invert & {
				color: $color-accent-invert !important;
			}
		}
		.u-text-base {
			color: $color-base !important;
			.c-color--invert & {
				color: $color-base-invert !important;
			}
		}
		.u-text-base-light {
			color: $color-base-light !important;
			.c-color--invert & {
				color: $color-base-invert-light !important;
			}
		}
		.u-text-base-lighter {
			color: $color-base-lighter !important;
			.c-color--invert & {
				color: $color-base-invert-lighter !important;
			}
		}
	} @else {
		@include media-breakpoint-up(#{$breakpoint-key}) {
			.u-text-accent\@#{$breakpoint-key} {
				color: $color-accent !important;
				.c-color--invert & {
					color: $color-accent-invert !important;
				}
			}
			.u-text-base\@#{$breakpoint-key} {
				color: $color-base !important;
				.c-color--invert & {
					color: $color-base-invert !important;
				}
			}
			.u-text-base-light\@#{$breakpoint-key} {
				color: $color-base-light !important;
				.c-color--invert & {
					color: $color-base-invert-light !important;
				}
			}
			.u-text-base-lighter\@#{$breakpoint-key} {
				color: $color-base-lighter !important;
				.c-color--invert & {
					color: $color-base-invert-lighter !important;
				}
			}
		}
	}
}
