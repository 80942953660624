
$animation-speed-min: .1s;
$animation-speed-faster: .5s;
$animation-speed: 2s;
$animation-speed-slower: 5s;


@keyframes cardAlertOpen {
	from {
		bottom: 100%;
	}
	to {
		bottom: 0;
	}
}

@keyframes tabsContentActive {
	0% {
		opacity: 0;
		transform: translateY(1rem);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}


@keyframes buttonLoading {
	0%, 100% {
		transform: scale(0.0);
		-webkit-transform: scale(0.0);
	}
	50% {
		transform: scale(1.0);
		-webkit-transform: scale(1.0);
	}
}

@keyframes circleLoadingPage {
	0% {
		opacity: 0;
	}
	50%, 100% {
		opacity: 1;
	}
}
@keyframes circleLoadingPageReverse {
	0%, 50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
	}
}
@keyframes circleLoadingHalf {
	0% {
		transform: scale(0.1);
		-webkit-transform: scale(0.0);
	}
	100% {
		transform: scale(1.0);
		-webkit-transform: scale(1.0);
	}
}
@keyframes circleLoadingHalfReverse {
	0% {
		transform: scale(1.0);
		-webkit-transform: scale(1.0);
	}
	100% {
		transform: scale(0.0);
		-webkit-transform: scale(0.0);
	}
}

@keyframes buttonSuccess {
	0% {
		opacity: 0;
		visibility: visible;
		background-size: rem(32px);
	}
	25%, 100% {
		opacity: 1;
		visibility: visible;
		background-size: rem(16px);
	}
}

@keyframes lighthouseShine {
	0%,
	100% {
		opacity: 0;
	}
	25%,
	75% {
		opacity: 0.1;
	}
	50% {
		opacity: .2;
	}
}
@keyframes lighthouseLights {
	0% {
		transform: perspective(100px) rotateY(0deg);
	}
	100% {
		transform: perspective(100px) rotateY(360deg);
	}
}

@keyframes loadingSkeleton {
  100% {
    transform: translateX(100%);
  }
}

@keyframes loadingBar {
	100% {
		margin-left: 100%;
	}
}

@keyframes loadingFormField {
	0% {
		transform: translateX(-100%);
	}

	100% {
		transform: translateX(100%);
	}
}
