/* =============================================================================
   BUTTONS (components/_buttons.scss)
   ========================================================================== */

.c-button {
	display: inline-block;
	padding: $button-padding-horizontal;
	padding-top: ($button-height - $button-text-size - (2 * $button-border-width)) / 2;
	padding-bottom: ($button-height - $button-text-size - (2 * $button-border-width)) / 2;
	line-height: 1;
	font-size: $button-text-size;
	font-weight: $button-font-weight;
	text-align: center;
	border-radius: $button-border-radius;
	border: $button-border-width solid transparent;
	transition: all $transition-speed;
	box-shadow: $button-shadow;
	cursor: pointer;

	span {
		transition: opacity $transition-speed;
	}

	&:hover {
		outline: none;
		box-shadow: $button-shadow-hover;
	}
	&.c-button--stroke {
		box-shadow: $button-stroke-shadow;

		&:hover {
			box-shadow: $button-stroke-shadow-hover;
		}
	}
	.c-color--invert & {
		box-shadow: $button-shadow-invert;

		&:hover {
			box-shadow: $button-shadow-invert-hover;
		}
		&.c-button--stroke {
			box-shadow: $button-stroke-shadow-invert;

			&:hover {
				box-shadow: $button-stroke-shadow-invert-hover;
			}
		}
	}
}



/* Button Styles - Default
   ========================================================================== */

.c-button {
	color: $button-color;
	background-color: $button-background;
	border-color: $button-border-color;

	&:hover {
		color: $button-color-hover;
		background-color: $button-background-hover;
		border-color: $button-border-color-hover;
	}
	&.c-button--stroke {
		color: $button-stroke-color;
		background-color: $button-stroke-background;
		border-color: $button-stroke-border-color;

		&:hover {
			color: $button-stroke-color-hover;
			background-color: $button-stroke-background-hover;
			border-color: $button-stroke-border-color-hover;
		}
	}
	.c-color--invert & {
		color: $button-color-invert;
		background-color: $button-background-invert;
		border-color: $button-border-color-invert;

		&:hover {
			color: $button-color-invert-hover;
			background-color: $button-background-invert-hover;
			border-color: $button-border-color-invert-hover;
		}
		&.c-button--stroke {
			color: $button-stroke-color-invert;
			background-color: $button-stroke-background-invert;
			border-color: $button-stroke-border-color-invert;

			&:hover {
				color: $button-stroke-color-invert-hover;
				background-color: $button-stroke-background-invert-hover;
				border-color: $button-stroke-border-color-invert-hover;
			}
		}
	}
}

/* Button Styles - White
   ========================================================================== */

.c-button--white {
	color: $button-white-color;
	background-color: $button-white-background;
	border-color: $button-white-border-color;

	&:hover {
		color: $button-white-color-hover;
		background-color: $button-white-background-hover;
		border-color: $button-white-border-color-hover;
	}
	&.c-button--stroke {
		color: $button-white-stroke-color;
		background-color: $button-white-stroke-background;
		border-color: $button-white-stroke-border-color;

		&:hover {
			color: $button-white-stroke-color-hover;
			background-color: $button-white-stroke-background-hover;
			border-color: $button-white-stroke-border-color-hover;
		}
	}
	.c-color--invert & {
		color: $button-white-color-invert;
		background-color: $button-white-background-invert;
		border-color: $button-white-border-color-invert;

		&:hover {
			color: $button-white-color-invert-hover;
			background-color: $button-white-background-invert-hover;
			border-color: $button-white-border-color-invert-hover;
		}
		&.c-button--stroke {
			color: $button-white-stroke-color-invert;
			background-color: $button-white-stroke-background-invert;
			border-color: $button-white-stroke-border-color-invert;

			&:hover {
				color: $button-white-stroke-color-invert-hover;
				background-color: $button-white-stroke-background-invert-hover;
				border-color: $button-white-stroke-border-color-invert-hover;
			}
		}
	}
}

/* Button Styles - Primary
   ========================================================================== */

.c-button--primary {
	color: $button-primary-color;
	background-color: $button-primary-background;
	border-color: $button-primary-border-color;

	&:hover {
		color: $button-primary-color-hover;
		background-color: $button-primary-background-hover;
		border-color: $button-primary-border-color-hover;
	}
	&.c-button--stroke {
		color: $button-primary-stroke-color;
		background-color: $button-primary-stroke-background;
		border-color: $button-primary-stroke-border-color;

		&:hover {
			color: $button-primary-stroke-color-hover;
			background-color: $button-primary-stroke-background-hover;
			border-color: $button-primary-stroke-border-color-hover;
		}
	}
	.c-color--invert & {
		color: $button-primary-color-invert;
		background-color: $button-primary-background-invert;
		border-color: $button-primary-border-color-invert;

		&:hover {
			color: $button-primary-color-invert-hover;
			background-color: $button-primary-background-invert-hover;
			border-color: $button-primary-border-color-invert-hover;
		}
		&.c-button--stroke {
			color: $button-primary-stroke-color-invert;
			background-color: $button-primary-stroke-background-invert;
			border-color: $button-primary-stroke-border-color-invert;

			&:hover {
				color: $button-primary-stroke-color-invert-hover;
				background-color: $button-primary-stroke-background-invert-hover;
				border-color: $button-primary-stroke-border-color-invert-hover;
			}
		}
	}
}

/* Button Styles - Secondary
   ========================================================================== */

.c-button--secondary {
	color: $button-secondary-color;
	background-color: $button-secondary-background;
	border-color: $button-secondary-border-color;

	&:hover {
		color: $button-secondary-color-hover;
		background-color: $button-secondary-background-hover;
		border-color: $button-secondary-border-color-hover;
	}
	&.c-button--stroke {
		color: $button-secondary-stroke-color;
		background-color: $button-secondary-stroke-background;
		border-color: $button-secondary-stroke-border-color;

		&:hover {
			color: $button-secondary-stroke-color-hover;
			background-color: $button-secondary-stroke-background-hover;
			border-color: $button-secondary-stroke-border-color-hover;
		}
	}
	.c-color--invert & {
		color: $button-secondary-color-invert;
		background-color: $button-secondary-background-invert;
		border-color: $button-secondary-border-color-invert;

		&:hover {
			color: $button-secondary-color-invert-hover;
			background-color: $button-secondary-background-invert-hover;
			border-color: $button-secondary-border-color-invert-hover;
		}
		&.c-button--stroke {
			color: $button-secondary-stroke-color-invert;
			background-color: $button-secondary-stroke-background-invert;
			border-color: $button-secondary-stroke-border-color-invert;

			&:hover {
				color: $button-secondary-stroke-color-invert-hover;
				background-color: $button-secondary-stroke-background-invert-hover;
				border-color: $button-secondary-stroke-border-color-invert-hover;
			}
		}
	}
}



/* Button Icons
   ========================================================================== */

.c-button {
	.o-svg-icon {
		top: ($button-text-size - $button-icon-size) / 2;
		left: - $button-padding-horizontal * 1 / 6;
		margin-right: $button-padding-horizontal * 2 / 5;
		font-size: $button-icon-size;
		opacity: $button-icon-opacity;
		transition: opacity $transition-speed;
	}
	span {
		+ .o-svg-icon {
			left: auto;
			right: - $button-padding-horizontal * 1 / 6;
			margin-right: 0;
			margin-left: $button-padding-horizontal * 2 / 5;
		}
	}

	&:hover {
		.o-svg-icon {
			opacity: $button-icon-opacity-hover;
		}
	}
}
.c-button--icon {
	position: relative;
	width: $button-height;
	height: $button-height;
	white-space: nowrap;
	padding-left: 0;
	padding-right: 0;

	.o-svg-icon {
		position: absolute;
		top: 50%;
		left: 50%;
		margin: 0;
		margin-left: -0.5em;
		margin-top: -0.5em;
	}
}



/* Button sizes
   ========================================================================== */

@mixin button-short {
	padding: $button-padding-horizontal-small;
	padding-top: ($button-height-small - $button-text-size - (2 * $button-border-width)) / 2;
	padding-bottom: ($button-height-small - $button-text-size - (2 * $button-border-width)) / 2;
	border-radius: $button-border-radius-small;
	.o-svg-icon {
		left: - $button-padding-horizontal-small * 1 / 6;
		margin-right: $button-padding-horizontal-small * 2 / 5;
	}
	span {
		+ .o-svg-icon {
			left: auto;
			right: - $button-padding-horizontal-small * 1 / 6;
			margin-right: 0;
			margin-left: $button-padding-horizontal-small * 2 / 5;
		}
	}
	&.c-button--icon {
		width: $button-height-small;
		height: $button-height-small;
		.o-svg-icon {
			left: 50%;
		}
	}
	&[class*="c-button--success"] {
		&:after {
			border-radius: $button-border-radius-small;
		}
	}
}
@mixin button-small {
	@include button-short;
	padding-top: ($button-height-small - $button-text-size-small - (2 * $button-border-width)) / 2;
	padding-bottom: ($button-height-small - $button-text-size-small - (2 * $button-border-width)) / 2;
	font-size: $button-text-size-small;
	.o-svg-icon {
		top: ($button-text-size-small - $button-icon-size-small) / 2;
		font-size: $button-icon-size-small;
	}
	&.c-button--icon {
		.o-svg-icon {
			top: 50%;
		}
	}
}
@mixin button-high {
	padding: $button-padding-horizontal-large;
	padding-top: ($button-height-large - $button-text-size - (2 * $button-border-width)) / 2;
	padding-bottom: ($button-height-large - $button-text-size - (2 * $button-border-width)) / 2;
	border-radius: $button-border-radius-large;
	.o-svg-icon {
		left: - $button-padding-horizontal-large * 1 / 6;
		margin-right: $button-padding-horizontal-large * 2 / 5;
	}
	span {
		+ .o-svg-icon {
			left: auto;
			right: - $button-padding-horizontal-large * 1 / 6;
			margin-right: 0;
			margin-left: $button-padding-horizontal-large * 2 / 5;
		}
	}
	&.c-button--icon {
		width: $button-height-large;
		height: $button-height-large;
		.o-svg-icon {
			left: 50%;
		}
	}
	&[class*="c-button--success"] {
		&:after {
			border-radius: $button-border-radius-large;
		}
	}
}
@mixin button-large {
	@include button-high;
	padding-top: ($button-height-large - $button-text-size-large - (2 * $button-border-width)) / 2;
	padding-bottom: ($button-height-large - $button-text-size-large - (2 * $button-border-width)) / 2;
	font-size: $button-text-size-large;
	.o-svg-icon {
		top: ($button-text-size-large - $button-icon-size-large) / 2;
		font-size: $button-icon-size-large;
	}
	&.c-button--icon {
		.o-svg-icon {
			top: 50%;
		}
	}
}

.c-button--short,
input.c-button--short {
	@include button-short;
}
.c-button--small,
input.c-button--small {
	@include button-small;
}
.c-button--high,
input.c-button--high {
	@include button-high;
}
.c-button--large,
input.c-button--large {
	@include button-large;
}

.c-button--full,
input.c-button--full {
	display: block;
	width: 100%;
}
.c-button--sm,
input.c-button--sm {
	min-width: 100px;
}
.c-button--md,
input.c-button--md {
	min-width: 150px;
}
.c-button--lg,
input.c-button--lg {
	min-width: 200px;
}
.c-button--xl,
input.c-button--xl {
	min-width: 250px;
}


/* Button group
   ========================================================================== */

.c-button-group {
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: $spacer-base-small;
	margin-left: - ($grid-gutter / 4);
	margin-right: - ($grid-gutter / 4);

	[class^=o-col], [class*=" o-col"] {
		width: 100%;
		padding-left: ($grid-gutter / 4);
		padding-right: ($grid-gutter / 4);
		margin-top: $spacer-base-small;
		margin-bottom: $spacer-base-small;
	}
	.c-button,
	[class*="c-link-cta"] {
		width: 100%;
		text-align: center;
		justify-content: center;
	}
	.c-button-group__inline {
		width: auto;
		flex-grow: 1;
	}

	@each $breakpoint-key, $breakpoint-value in $breakpoints {
		@include media-breakpoint-up(#{$breakpoint-key}) {
			&.c-button-group--row\@#{$breakpoint-key} {
				[class^=o-col], [class*=" o-col"] {
					width: auto;
					flex-grow: 1;
				}
			}
		}
	}
}


/* Status buttons
   ========================================================================== */

.c-button[disabled],
.c-button:disabled,
.c-button--disabled,
input.c-button--disabled {
	cursor: not-allowed;
	pointer-events: none;

	&:not(.c-button--loading):not([class*="c-button--success"]) {
		color: $color-accent-invert !important;
		background: darken($color-background-base, 30%) !important;
		border-color: transparent !important;
		box-shadow: none !important;
		pointer-events: inherit;
		opacity: .5;
	}

	.c-color--invert & {
		&:not(.c-button--loading):not([class*="c-button--success"]) {
			color: $color-accent !important;
			background: lighten($color-background-dark, 30%) !important;
		}
	}
}

.c-button--loading {
	position: relative;
	overflow: hidden;
	cursor: progress;

	span,
	.o-svg-icon {
		opacity: .3;
	}

	&:before,
	&:after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: $button-height * 2;
		height: $button-height * 2;
		margin-top: - ($button-height * 2) / 2;
		margin-left: - ($button-height * 2) / 2;
		border-radius: 50%;
		background-color: #888;
		opacity: 0.2;
		animation-name: buttonLoading;
		animation-duration: $animation-speed;
		animation-iteration-count: infinite;
		animation-timing-function: ease-in-out;
	}
	&:after {
		opacity: .8;
		background-color: lighten(color(primary), (2 * $color-shade-percentage));
		animation-delay: -1.0s;
	}

	.c-color--invert & {
		&:before {
			opacity: .3;
		}
		&:after {
			opacity: .6;
		}
	}
}

[class*="c-button--success"] {
	position: relative;
	pointer-events: none;

	&:after {
		content: '';
		position: absolute;
		top: - $button-border-width;
		left: - $button-border-width;
		right: - $button-border-width;
		bottom: - $button-border-width;
		border-radius: $button-border-radius;
		background: color(extra,success) $form-check-icon-path 50% 50% no-repeat;
		background-size: rem(16px);
		opacity: 0;
		visibility: hidden;
		animation-name: buttonSuccess;
		animation-duration: $animation-speed;
		animation-iteration-count: 2;
		animation-direction: alternate;
	}
}
.c-button--success-infinite {
	&:after {
		animation-iteration-count: 1;
		animation-fill-mode: forwards;
	}
}
