
.c-pagination {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	padding-top: $spacer-base-small;
	padding-bottom: $spacer-base-small;
	@include media-breakpoint-up(md) {
		flex-wrap: nowrap;
	}

	li {
		a,
		.c-pagination__link,
		> span {
			@include link-cta-basic;
			display: inline-flex;
			align-items: center;
			height: rem(36px);
		}
		> span,
		.c-pagination__link--disabled {
			color: $color-base;
			font-weight: $font-weight-accent;
			cursor: auto;

			span {
				&:before {
					display: none;
				}
			}
			.o-svg-icon {
				color: $color-base;
				opacity: 1;
				left: 0 !important;
			}
			&:hover {
				color: $color-base;
				.o-svg-icon {
					color: $color-base;
					opacity: 1;
				}
			}
			.c-color--invert & {
				&:hover {
					color: $color-base-invert;
					.o-svg-icon {
						color: $color-base-invert;
					}
				}
			}
		}
		&:not(.c-pagination__item--prev):not(.c-pagination__item--next) {
			a,
			.c-pagination__link,
			> span {
				min-width: rem(36px);
				justify-content: center;
				text-align: center;
			}
		}
	}

	.c-pagination__item--prev,
	.c-pagination__item--next {
		position: relative;
		flex-basis: 50%;
		min-width: rem(100px);
		flex-shrink: 0;
		order: -1;
		padding-left: $grid-gutter / 2;
		margin-bottom: $spacer-base-small;
		@include media-breakpoint-up(md) {
			flex-basis: auto;
			order: 0;
			margin-bottom: 0;
		}

		> span,
		.c-pagination__link--disabled {
			opacity: .35;
		}
	}
	.c-pagination__item--prev {
		text-align: right;
		padding-left: 0;
		padding-right: $grid-gutter / 2;
	}
	.c-pagination__item--active {
		a,
		.c-pagination__link,
		> span {
			color: $color-accent-invert;
			background: color(primary);
			border-radius: $border-radius-large;

			span {
				&:before {
					display: none;
				}
			}
		}
	}
}
