
.c-lighthouse {
	position: relative;
	z-index: 1;
	max-width: rem(660px);
	margin-left: auto;
	margin-right: auto;

	img {
		width: 100%;
	}
}

.c-lighthouse__wrapper {
	.c-block {
		position: static;

		[class*="o-container"] {
			position: static;
		}
	}
	.c-site-header,
	.c-site-footer {
		z-index: 10;
	}
	.c-block__content {
		position: relative;
		z-index: 10;
	}
}

.c-lighthouse__overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: color(primary);
	z-index: 1;
	opacity: 0;
	animation: lighthouseShine $animation-speed-slower linear infinite both;
	animation-delay: - $animation-speed-slower;
	pointer-events: none;
}

.c-lighthouse__lights {
	width: 400px;
	height: 40%;
	position: absolute;
	top: 20%;
	left: calc(50% - 200px);
	z-index: -1;
	opacity: .1;
	pointer-events: none;
	animation: lighthouseLights (2 * $animation-speed-slower) linear infinite both;

	&:before,
	&:after {
		position: absolute;
		content: '';
		width: 60%;
		height: 30%;
		top: 23%;
	}
	&:before {
		right: 30%;
		background-image: linear-gradient(to left, color(primary) 40%, transparent);
		transform: perspective(100px) rotateY(40deg);
	}
	&:after {
		left: 30%;
		background-image: linear-gradient(to right, color(primary) 40%, transparent);
		transform: perspective(100px) rotateY(-40deg);
	}
}
