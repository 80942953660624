
#sisenseApp {
	font-family: $font-family-base !important;

	.sis-scope {
		font-family: $font-family-base;
		background: transparent;

		.widget-body {
			background: transparent;
		}

		.menu-popup {
			margin-top: -80px;
		}

		.tipper-host {
			margin-top: -80px;
		}
	}

	.sisense-css-module {
		/* pivot table */
		.pivot-container {
			margin-left: -8px;
			margin-right: -8px;
		}
		.sisense-pivot {
			.table-grid {
				.table-grid__row {
					border-color: transparent;

					.table-grid__cell {
						font-family: $font-family-base;
						font-weight: $font-weight-base;
						color: $color-base-light;
						background-color: transparent;
						border-top-color: transparent;
						border-left-color: $color-background-base;
						border-right-color: $color-background-base;
						border-bottom-color: $color-border-base-dark;
						border-radius: 0;

						&.table-grid__cell--level-first {
							font-weight: $font-weight-accent;
							color: $color-base;
						}

						.table-grid__cell-corner {
							visibility: hidden;
						}
					}
					.table-grid__cell--sibling-odd,
					.table-grid__cell--row-sibling-odd {
						background: rgba($color-background-light,.75);
					}
					.table-grid__cell--user-type-grandTotal,
					.table-grid__cell--row-user-type-grandTotal {
						font-weight: $font-weight-accent;
						color: $color-base;
						border-top-color: $color-border-base-dark;
						border-left-color: transparent;
						border-right-color: transparent;
						border-bottom-color: transparent;
						background: $color-background-light2;

						&.table-grid__cell--level-first {
							font-weight: $font-weight-accent;
							border-bottom-left-radius: $border-radius;
						}
						&.table-grid__cell--row-level-last {
							&:last-child {
								border-bottom-right-radius: $border-radius;
							}
						}
					}
				}
				.table-grid__border-bottom {
					border-color: transparent;
				}
			}
			.multi-grid__top-part {
				.table-grid {
					.table-grid__row {
						border-color: transparent;

						.table-grid__cell {
							font-weight: $font-weight-accent;
							color: $color-base;
							background: rgba($color-background-light,.85);
							border-top-color: $color-border-base-dark;
							border-left-color: transparent;
							border-right-color: transparent;

							.table-grid__cell-corner {
								visibility: visible;
							}
						}
					}
					&.table-grid--left {
						.table-grid__row {
							.table-grid__cell--sibling-first {
								border-left-color: $color-border-base-dark;
								border-top-left-radius: $border-radius;
								border-bottom-left-radius: $border-radius;
							}
						}
					}
					&.table-grid--right {
						.table-grid__row {
							.table-grid__cell--sibling-last {
								border-right-color: $color-border-base-dark;
								border-top-right-radius: $border-radius;
								border-bottom-right-radius: $border-radius;
							}
						}
					}
				}
			}
		}

		/* aggregated table */
		.tablewidget-widget-content {
			padding-left: 0;
			padding-right: 0;

			.dataTables_wrapper {
				margin-bottom: $spacer-base-small;

				table {
					margin-bottom: 0;
					border-right-color: transparent !important;

					thead {
						td,
						th {
							font-family: $font-family-base;
							font-weight: $font-weight-accent;
							color: $color-base-lighter;
							border-left-color: transparent;
							border-right-color: transparent;
							border-top-color: $color-border-base;
							border-bottom-color: $color-border-base;

							&:first-child {
								border-left-color: $color-border-base;
								border-top-left-radius: $border-radius;
								border-bottom-left-radius: $border-radius;
							}
							&:last-child {
								border-right-color: $color-border-base;
								border-top-right-radius: $border-radius;
								border-bottom-right-radius: $border-radius;
							}
						}
						tr {
							background-color: transparent;

							&:hover {
								background-color: transparent !important;

								td,
								th {
									background-color: rgba($color-background-light,.75) !important;
								}
							}
						}
					}
					tbody {
						td,
						th {
							font-family: $font-family-base;
							font-weight: $font-weight-base;
							color: $color-base;
							border-left-color: transparent;
							border-right-color: transparent;
							border-bottom-color: $color-border-base;
						}
						tr {
							&:hover {
								background-color: $color-background-light2 !important;

								td,
								th {
									background-color: $color-background-light2 !important;
								}
							}
							&:nth-child(even):not(.child) {
								background: transparent;
							}
						}
					}
				}
				.c-table--has-footer {
					tbody {
						tr {
							&:last-child {
								td,
								th {
									font-weight: $font-weight-accent;
									border-top: 1px solid $color-border-base;

									&:first-child {
										font-weight: $font-weight-accent;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

#sisenseIframe {
	height: 100%;
	border: 0em;
}




.dataTables_scrollHead {
	table {
		margin-bottom: 0;
	}
}


.c-widget-tabs {
	> [class^=o-col],
	> [class*=" o-col"] {
		padding-left: $grid-gutter / 2;
		padding-right: $grid-gutter / 2;
	}

	.c-tabs-wrapper {
		blox {
			overflow: visible;
		}
		.font-weight-default {
			font-weight: inherit;
		}
		.font-size-default {
			font-size: inherit;
		}
		.color-default {
			color: inherit;
		}

		.blox-slides {
			position: relative;

			&:before,
			&:after {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				z-index: 2;
				width: $grid-gutter / 4;
				background: rgb(255,255,255);
				background: linear-gradient(90deg, rgba($color-background-base,1) 0%, rgba($color-background-base,0) 100%);
				pointer-events: none;
			}
			&:before {
				left: - $grid-gutter / 4;
			}
			&:after {
				right: - $grid-gutter / 4;
				transform: rotate(180deg);
			}
		}
		.container,
		.container.spacing-none {
			position: relative;
			padding: 0 !important;
			margin-bottom: $spacer-base;

			&:before {
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				top: 100%;
				margin-top: - $spacer;
				height: rem(1px);
				background: $color-border-base;
			}
		}
		.column-set {
			position: relative;
			display: flex;
			margin-left: - $grid-gutter / 4;
			margin-right: - $grid-gutter / 4;
			padding-bottom: $spacer;
			font-family: $font-family-base;
			width: auto !important;
			overflow: auto;
			-ms-overflow-style: none;
			scrollbar-width: none;

			&::-webkit-scrollbar {
				width: 0 !important;
				height: 0 !important;
				background: transparent !important;
			}

			.column,
			.column.spacing-none {
				@include link-cta-light;
				flex-grow: 0 !important;
				text-align: center;
				align-items: stretch;
				padding-left: $grid-gutter / 4;
				padding-right: $grid-gutter / 4;
				background: transparent !important;

				.text-block {
					@extend span;
					display: flex;
					align-items: center;
					padding-top: $spacer;
					padding-bottom: $spacer;
					line-height: $line-height-base;
					overflow: visible;

					&:before {
						left: 50%;
						right: auto;
						max-width: 100%;
						width: rem(30px);
						transform: translateX(-50%);
					}
				}

				&.is-selected {
					color: $color-links;

					.text-block {
						&:before {
							background-position-x: 0;
						}
					}
				}
			}
		}
	}
}
