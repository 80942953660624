
.c-notes {
	@extend .c-note;
	position: relative;
	margin-bottom: $spacer-base;
	margin-top: $spacer-base-large;

	p,
	li,
	td {
	}
	p,
	ul,
	ol,
	table {
		margin-bottom: $spacer;
	}

	&--info {
		position: relative;
		padding-left: 2em;

		&:before {
			content: 'i';
			position: absolute;
			top: 0;
			left: 0;
			width: 1.5em;
			height: 1.5em;
			line-height: 1.22;
			text-align: center;
			font-weight: $font-weight-base;
			font-style: italic;
			border: 1px solid $color-border-base;
			border-radius: 50%;
		}
	}

	&--dash {
		padding-top: $spacer-base;

		&:before {
			content: '';
			position: absolute;
			top:0;
			left:0;
			width: rem(60px);
			height: 1px;
			background: $color-border-base;
		}
	}
	&--border {
		padding-top: $spacer-base;
		border-top: 1px solid $color-border-base;
	}

	[class*="o-container"] > & {
		&:first-child {
			margin-top: 0;
		}
	}
}



/* Inverted color styles (dark background)
   ========================================================================== */

.c-color--invert {
	.c-notes {
		&--dash {
			&:before {
				background: $color-border-invert;
			}
		}
		&--border {
			border-color: $color-border-invert;
		}
		&--info {
			&:before {
				border-color: $color-border-invert;
			}
		}
	}
}
