.c-site-header {
  position: relative;
  left: 0;
  right: 0;
  transition: all $transition-speed;
}

.c-site-header__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  transition: all $transition-speed;

  .has-site-nav & {
    flex-wrap: wrap;
    @include media-breakpoint-up(lg) {
      flex-wrap: nowrap;
    }
  }
}

.c-site-header__main {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  flex-basis: 100%;
  align-items: center;
  max-width: 100%;

  .c-alert {
    z-index: 20;
    min-height: $popup-header-height;
    width: 100%;
    margin-bottom: 0;
    margin-top: ($header-height-mobile - $popup-header-height) / 2;
    @include media-breakpoint-up(sm) {
      min-height: $popup-header-height-large;
      margin-top: ($header-height-mobile - $popup-header-height-large) / 2;
    }
    @include media-breakpoint-up(md) {
      margin-top: ($header-height - $popup-header-height-large) / 2;
    }
    @include media-breakpoint-up(lg) {
      min-width: calc(
        100% + #{$popup-header-height-large} + (#{$grid-gutter} / 2)
      );
    }
  }
}

.c-site-header__headline {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  flex-basis: 100%;
  max-width: calc(100% + #{$grid-gutter});
  align-items: center;
  min-height: $header-height-mobile;
  margin-left: -$grid-gutter / 2;
  margin-right: -$grid-gutter / 2;
  padding-right: $grid-gutter / 2;
  padding-top: $spacer-base-small;
  padding-bottom: $spacer-base-small;
  @include media-breakpoint-up(md) {
    min-height: $header-height;
  }

  .c-site-logo {
    width: $logo-basic-hor-width-ratio * rem(40px);
    margin-left: $grid-gutter / 2;
    margin-right: $grid-gutter / 2;
  }

  .c-site-header__logo {
    display: flex;
    align-items: center;
    padding-left: $grid-gutter / 2;
    padding-right: $grid-gutter / 2;
    max-width: rem(160px) + ($grid-gutter / 2);
    height: $header-height-mobile - (2 * $spacer-base-small);

    img {
      display: block;
      max-height: 100%;

      &[src*=".svg"] {
        height: 100%;
      }
    }
    p {
      margin-left: 0;
      margin-right: 0;
      line-height: $line-height-medium;
      color: $color-base;
      font-weight: $font-weight-accent;

      .c-color--invert & {
        color: $color-base-invert;
      }
    }
  }
  .c-site-header__logo--higher {
    height: $header-height-mobile - (2 * $spacer);
  }
  .c-site-header__logo--highest {
    height: $header-height-mobile;
  }
  .c-site-header__group {
    position: relative;
    display: block;
    padding-left: $grid-gutter / 4;
    padding-right: $grid-gutter / 4;

    &:before {
      content: "";
      position: absolute;
      right: ($grid-gutter / 2);
      top: 0;
      bottom: 0;
      z-index: 10;
      width: $form-select-icon-size;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      opacity: $form-select-icon-opacity;
      pointer-events: none;
      background-image: $form-select-icon-path;
    }

    .c-site-header__logo {
      padding-left: $grid-gutter / 4;
      padding-right: $form-select-icon-size + 1rem;
      border-radius: $border-radius;
      transition: all $transition-speed;
    }

    > p {
      cursor: pointer;
      transition: all $transition-speed;

      &:hover {
        color: $color-base;
      }
    }

    .c-popup {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      min-width: rem(180px);
      max-width: rem(300px);
      @include media-breakpoint-up(sm) {
        max-width: rem(400px);
      }

      .c-popup__title {
        margin-left: 0;
        margin-right: 0;
        font-size: font-size(02);
      }
    }
    .c-popup__pointer {
      cursor: pointer;
    }

    &.has-popup-open {
      z-index: 100;

      &:before {
        transform: rotate(180deg);
      }

      .c-site-header__logo {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      > p {
        color: $color-base;
      }
    }

    .c-theme--dark & {
      &:before {
        background-image: $form-select-icon-path-invert;
      }
    }
  }

  .c-site-header__group--single {
    &::before {
      display: none;
    }

    .c-site-header__logo {
      padding-right: $grid-gutter / 4;
    }
  }

  p {
    position: relative;
    margin-bottom: 0;
    margin-left: $grid-gutter / 2;
    margin-right: $grid-gutter / 2;
    font-size: font-size(05);
    color: $color-base-lighter;

    &:before {
      content: "";
      position: absolute;
      left: -$grid-gutter / 2;
      top: 50%;
      height: rem(24px);
      width: rem(1px);
      background: $color-border-base;
      transform: translate(-50%, -50%);
    }
    [class*="c-link-cta"] {
      .o-svg-icon {
        opacity: 1;
      }
      &:hover {
        .o-svg-icon {
          opacity: 1;
        }
      }
    }

    .c-color--invert & {
      color: $color-base-invert-lighter;

      &:before {
        background: $color-border-invert;
      }
    }

    &:first-child {
      &:before {
        display: none;
      }
    }
  }
  .c-actions {
    margin-left: 0;
    margin-right: 0;
  }
}

.c-site-header__utility {
  flex-shrink: 0;
  margin-left: auto;
  display: flex;
  align-items: center;
  min-height: $header-height-mobile;
  @include media-breakpoint-up(md) {
    min-height: $header-height;
  }
  @include media-breakpoint-up(lg) {
    margin-right: -$horizontal-spacer-large + ($grid-gutter / 2) +
      $spacer-base-small;
  }

  .has-site-nav & {
    order: -1;
    width: 100%;
    justify-content: flex-end;
    @include media-breakpoint-up(lg) {
      order: 0;
      width: auto;
    }
  }
}

.c-site-header-clock {
  display: none;
  height: $header-height - $spacer-base-small;
  margin-top: -$spacer-base-small;
  padding-top: 0.25rem;
  padding-bottom: 0.375rem;
  margin-left: auto;
  @include media-breakpoint-up(md) {
    display: block;
  }
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    margin-top: -$spacer;
    height: auto;
    text-align: right;
  }

  p {
    color: $color-base-light;
    font-size: font-size(04);
    font-weight: $font-weight-accent;
    margin: 0;

    &::before {
      display: none;
    }

    .c-color--invert & {
      color: $color-base-invert;
    }
  }
}

p.c-site-header-clock__date {
  font-size: font-size(04);
  @include media-breakpoint-up(lg) {
    margin-right: $spacer-base;
  }
}

p.c-site-header-clock__time {
  line-height: $line-height-large;
  span {
    font-size: 2rem;
  }
}

.c-site-notifications {
  margin-left: $grid-gutter / 2;
  @include media-breakpoint-up(sm) {
    position: relative;
  }

  .c-site-notifications__toggle {
    @extend a;
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: $popup-header-height;
    width: $popup-header-height;
    color: $color-base-lighter;
    cursor: pointer;

    &:hover {
      color: $color-links;
    }
    .o-svg-icon {
      width: rem(20px);
      height: rem(20px);
    }
    .c-tag {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-rem(16px));
      border-color: $color-background-base;
      background-color: $color-links;

      .c-theme--dark & {
        border-color: $color-background-dark;
      }
    }

    &.c-popup__pointer {
      &:before {
        top: auto;
        bottom: -($header-height-mobile - $popup-header-height) / 2;
      }
    }

    .c-color--invert & {
      color: $color-base-invert-lighter;
    }
  }
  .c-popup {
    position: absolute;
    top: $header-height-mobile;
    right: 0;
    width: 100%;
    max-width: rem(300px);
    @include media-breakpoint-up(sm) {
      top: 0;
      right: 0;
      width: rem(300px);
      max-width: none;
    }

    .c-popup__toggle {
      @include media-breakpoint-up(sm) {
        display: none;
      }
    }
  }

  &.c-site-notifications--unread {
    .c-site-notifications__toggle {
      color: $color-links;

      &:hover {
        color: $color-links-darker;
      }

      .c-color--invert & {
        color: $color-links;

        &:hover {
          color: $color-links-lighter;
        }
      }
    }
  }

  &.has-popup-open,
  .has-popup-open & {
    z-index: 100;

    .c-site-notifications__toggle {
      color: $color-base;
      @include media-breakpoint-up(sm) {
        color: $color-base-invert;
      }

      .c-tag {
        @include media-breakpoint-up(sm) {
          background-color: $color-links-lighter;
          border-color: $color-background-dark;
        }
      }

      &.c-popup__pointer {
        &:before {
          visibility: visible;
          opacity: 1;
          transform: translateY(0);

          @include media-breakpoint-up(sm) {
            display: none;
          }
        }
      }

      .c-color--invert & {
        color: $color-base-invert;
        @include media-breakpoint-up(sm) {
          color: $color-base-invert;
        }
      }
    }

    &.c-site-notifications--unread {
      .c-site-notifications__toggle {
        color: $color-links;
        @include media-breakpoint-up(sm) {
          color: $color-links-lighter;
        }

        &:hover {
          color: $color-links-darker;
          @include media-breakpoint-up(sm) {
            color: $color-links-lighter;
          }
        }
      }
    }
  }
}

.c-site-user {
  margin-left: $grid-gutter / 2;
  @include media-breakpoint-up(sm) {
    position: relative;
  }

  .c-site-user__toggle {
    @extend a;
    position: relative;
    z-index: 10;
    cursor: pointer;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: $popup-header-height;
      height: $popup-header-height;
      border-radius: $border-radius-large;
      border: 2px solid lighten($color-border-base, 30%);
      pointer-events: none;
      transition: all $transition-speed;
      @include media-breakpoint-up(sm) {
        width: $popup-header-height-large;
        height: $popup-header-height-large;
      }

      .c-theme--dark & {
        border-color: $color-border-base-darker;
      }
    }
    .c-site-user__toggle-initial {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $popup-header-height;
      height: $popup-header-height;
      color: color-darken(secondary);
      background: $color-background-light;
      border-radius: $border-radius-large;
      @include media-breakpoint-up(sm) {
        width: $popup-header-height-large;
        height: $popup-header-height-large;
      }

      .c-theme--dark & {
        color: color(secondary);
        background: color(neutral, 700);
      }
    }
    img {
      display: block;
      width: $popup-header-height;
      height: $popup-header-height;
      border-radius: $border-radius-large;
      @include media-breakpoint-up(sm) {
        width: $popup-header-height-large;
        height: $popup-header-height-large;
      }
    }
    .o-svg-icon {
      display: block;
      position: absolute;
      top: 50%;
      right: -(($grid-gutter / 2) - font-size(04)) / 2 - font-size(04);
      margin-top: -0.5em;
      color: $color-base-lighter;
      transition: color $transition-speed;
    }

    &:hover {
      &:after {
        border-color: $color-border-base-dark;

        .c-theme--dark & {
          border-color: $color-border-base-darkest;
        }
      }
      .o-svg-icon {
        opacity: 1;
        color: $color-links;
      }
    }

    &.c-popup__pointer {
      &:before {
        top: auto;
        bottom: -($header-height-mobile - $popup-header-height) / 2;
      }
    }

    .c-color--invert & {
      .o-svg-icon {
        color: $color-base-invert-lighter;
      }
      &:hover {
        .o-svg-icon {
          color: $color-links;
        }
      }
    }
    .c-color--default & {
      .o-svg-icon {
        color: $color-base-lighter;
      }
      &:hover {
        .o-svg-icon {
          color: $color-links;
        }
      }
    }
  }
  .c-popup {
    position: absolute;
    top: $header-height-mobile;
    right: -$grid-gutter / 4;
    min-width: rem(150px);
    max-width: rem(320px) - ($grid-gutter / 2);
    @include media-breakpoint-up(sm) {
      top: 0;
      right: 0;
      min-width: rem(200px);
    }

    .c-popup__toggle {
      @include media-breakpoint-up(sm) {
        display: none;
      }
    }
    .c-popup__username {
      @include media-breakpoint-up(sm) {
        text-align: right;
        margin-right: $spacer;
      }
    }
  }

  &.has-popup-open,
  .has-popup-open & {
    z-index: 100;

    .c-site-user__toggle {
      .o-svg-icon {
        color: $color-links-lighter;
      }
      &:hover {
        .o-svg-icon {
          color: $color-links;
        }
      }
      &.c-popup__pointer {
        &:before {
          visibility: visible;
          opacity: 1;
          transform: translateY(0);

          @include media-breakpoint-up(sm) {
            display: none;
          }
        }
      }

      .c-theme--dark & {
        &:after {
          border-color: $color-border-base-darkest;
        }
      }
    }
  }
}
